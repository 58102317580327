import { Grid, Typography } from "@material-ui/core";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailIcon from "@mui/icons-material/Mail";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ContactForm from "../../components/ContactForm";
import useWindowSize from "../../hooks/useWindowSize";
import "./styles.css";

const ContactUs = () => {
  const windowSize = useWindowSize();

  const onLinkedinClicked = () => {
    window.open("https://www.linkedin.com/company/deep-surface-ai/", "_blank");
  };

  const onMailClicked = () => {
    window.open("mailto:info@deepsurface.ai", "_blank");
  };

  const mobilePageLayout = () => {
    return (
      <Grid
        container
        direction="column"
        spacing={4}
        className="mobile-content-container"
      >
        <Grid item xs={10}>
          <Typography variant="h3">Request a Demo!</Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body2">
            Interested in a demo? Want to learn more about how we can optimize
            your practice? Simply fill out the form and we’ll be in touch.
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            spacing={2}
            className="mobile-contact-info"
          >
            <Grid item>
              <LocationOnIcon />
            </Grid>
            <Grid item>
              <Typography variant="body2">Calgary, Alberta</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <MailIcon onClick={onMailClicked} />
            </Grid>
            <Grid item>
              <Typography onClick={onMailClicked} variant="body2">
                info@deepsurface.ai
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <ContactForm />
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            spacing={2}
            justifyContent="center"
            className="mobile-icons-container"
          >
            <Grid item>
              <LinkedInIcon onClick={onLinkedinClicked} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const desktopPageLayout = () => {
    return (
      <Grid
        container
        direction="row"
        spacing={2}
        className="contact-us-page-container"
      >
        <Grid item xs={1} />
        <Grid item xs={3}>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <Typography variant="h3">Request a Demo!</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                Interested in a demo? Want to learn more about how we can
                optimize your practice? Simply fill out the form and we’ll be in
                touch.
              </Typography>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <LocationOnIcon />
                </Grid>
                <Grid item>
                  <Typography variant="body2">Calgary, Alberta</Typography>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <MailIcon
                    className="clickable-icon"
                    onClick={onMailClicked}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    onClick={onMailClicked}
                    className="hoverable-text"
                    variant="body2"
                  >
                    info@deepsurface.ai
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <LinkedInIcon
                    className="clickable-icon"
                    onClick={onLinkedinClicked}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    onClick={onLinkedinClicked}
                    className="hoverable-text"
                    variant="body2"
                  >
                    Connect with us
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}></Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={7}>
          <ContactForm />
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      {windowSize.width > 640 ? desktopPageLayout() : mobilePageLayout()}
    </div>
  );
};

export default ContactUs;
