import "./styles.css";
import BlogPages from "./blogPages";
import { Link, Grid, Typography } from "@material-ui/core";

const data = [
  {
    title: 'The Power of AI with Intent and Purpose',
    date: 'JULY 14, 2023',
    content: 
    `
    <html>

    <head>
        <meta content="text/html; charset=UTF-8" http-equiv="content-type">
        <style type="text/css">
            ol {
                margin: 0;
                padding: 0
            }
    
            table td,
            table th {
                padding: 0
            }
    
            .c2 {
                color: #000000;
                font-weight: 400;
                text-decoration: none;
                vertical-align: baseline;
                font-size: 13pt;
                line-height: 1.5;
                font-family: "Arial";
                font-style: normal
            }
    
            .c0 {
                padding-top: 0pt;
                padding-bottom: 0pt;
                line-height: 1.5;
                orphans: 2;
                widows: 2;
                text-align: left;
                height: 11pt
            }
    
            .c5 {
                color: #000000;
                font-weight: 700;
                text-decoration: none;
                vertical-align: baseline;
                font-size: 13pt;
                line-height: 1.5;
                font-family: "Arial";
                font-style: normal
            }
    
            .c1 {
                padding-top: 0pt;
                padding-bottom: 0pt;
                line-height: 1.5;
                orphans: 2;
                widows: 2;
                text-align: left
            }
    
            .c4 {
                text-decoration-skip-ink: none;
                -webkit-text-decoration-skip: none;
                color: #1155cc;
                line-height: 1.5;
                text-decoration: underline
            }
    
            .c6 {
                background-color: #ffffff;
                max-width: 468pt;
                line-height: 1.5;
                padding: 72pt 72pt 72pt 72pt
            }
    
            .c3 {
                color: inherit;
                line-height: 1.5;
                text-decoration: inherit
            }
    
            .c7 {
                font-weight: 700
            }
    
            .title {
                padding-top: 0pt;
                color: #000000;
                font-size: 29pt;
                padding-bottom: 3pt;
                font-family: "Arial";
                line-height: 1.5;
                page-break-after: avoid;
                orphans: 2;
                widows: 2;
                text-align: left
            }
    
            .subtitle {
                padding-top: 0pt;
                color: #666666;
                font-size: 17pt;
                padding-bottom: 16pt;
                font-family: "Arial";
                line-height: 1.5;
                page-break-after: avoid;
                orphans: 2;
                widows: 2;
                text-align: left
            }
    
            li {
                color: #000000;
                font-size: 13pt;
                line-height: 1.5;
                font-family: "Arial"
            }
    
            p {
                margin: 0;
                color: #000000;
                font-size: 13pt;
                line-height: 1.5;
                font-family: "Arial"
            }

            @media (max-width: 568px){

              .c6 {
                  font-size: 13pt;
              }
      
              .c2 {
                  font-size: 14pt;
              }
      
      
              li {
                  font-size: 13pt;
              }
      
              p {
                  font-size: 13pt;
              }
      
              }
        </style>
    </head>
    
    <body class="c6 doc-content">
        <p class="c1"><span>Tim Cruz, Deep Surface AI&rsquo;s CTO, was invited on the Collision YYC podcast to speak on how
                to leverage artificial intelligence effectively. In the podcast, Cruz emphasized that AI </span><span>should
                be</span><span class="c2">&nbsp;viewed as an engine that drives innovation and growth. Successful AI
                implementation requires comprehensive infrastructure that encompasses data management, ethical
                considerations, and transparent communication. By acknowledging the holistic nature of AI, organizations can
                navigate the complexities of integrating AI into their operations and maximize its potential impact.</span>
        </p>
        <br />
        <p class="c1"><span class="c2">Artificial intelligence has become an integral part of the modern technological
                landscape. Tim Cruz&#39;s appearance on the Collision YYC podcast shed light on the importance of adopting
                AI with intent and purpose. As AI continues to shape various industries, understanding its transformative
                power and embracing change becomes crucial for success in high-stakes environments.</span></p>
        <p class="c0"><span class="c2"></span></p>
        <br />
        <p class="c1"><span>Listen to the full episode here: </span><span class="c7">E343 - Current &amp; Critical - Tim
                Cruz, Artificial Intelligence - How to use it with intent &amp; purpose</span></p>

                <a
                class="c1"
                target="_blank"
                href="https://www.collisionsyyc.com/episodes/e343-tim-cruz.">Collisions/YYC,
                 </a>

                 <a
                class="c1"
                target="_blank"
                href="https://podcasts.apple.com/ca/podcast/collisions-yyc/id1474543095?i=1000614187513.">Apple Podcasts,
                 </a>

                 <span>or</span>

                 <a
                class="c1"
                target="_blank"
                href="https://open.spotify.com/episode/3FXJD7a0l2tyXn9juWiHTT?si=3eb08199db924ca5&nd=1..">Spotify
                 </a>
    </body>
    
    </html>    
    `,
    link: [
      {
        text: 'Apple Podcasts',
        url: 'https://podcasts.apple.com/ca/podcast/collisions-yyc/id1474543095?i=1000614187513',
      },
      {
        text: 'Spotify',
        url: 'https://open.spotify.com/episode/3FXJD7a0l2tyXn9juWiHTT?si=3eb08199db924ca5&nd=1',
      },
    ], 
    image: '/blog/new-blog-img.jpeg', 
    imageAlt: 'tim-blog-img'
  }
]

const TimBlog = () => {
  return (
    <BlogPages data={data[0]}>
        <Grid item id="blog-document-paragraph" >
            <Typography variant="body2" align="left" id="blog-document-header-text">
                <div dangerouslySetInnerHTML={{ __html: data[0].content}} />
            </Typography>
          </Grid>
    </BlogPages>
  )
}

export default TimBlog

