import { Grid, Typography } from "@material-ui/core";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailIcon from "@mui/icons-material/Mail";
// import PhoneIcon from "@mui/icons-material/Phone";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
// import TwitterIcon from '@mui/icons-material/Twitter';
import "./styles.css";
import { useHistory } from "react-router";
import useWindowSize from "../../hooks/useWindowSize";

const Footer = () => {
  const history = useHistory();
  const windowSize = useWindowSize();

  const onPolicyClicked = () => {
    history.push("/privacy-policy");
  };

  const onBlogClicked = () => {
    history.push("/blog");
  };

  const onSurgeonClicked = () => {
    history.push("/surgeon-info");
  };

  const onPatientClicked = () => {
    history.push("/patient-info");
  };

  const onAboutUsClicked = () => {
    history.push("/about-us");
  };

  const onContactUsClicked = () => {
    history.push("/contact-us");
  };

  const onLogoClicked = () => {
    history.push("/");
  };

  const onLinkedinClicked = () => {
    window.open("https://www.linkedin.com/company/deep-surface-ai/", "_blank");
  };

  const onInstagramClicked = () => {
    window.open("https://www.instagram.com/deepsurfaceai/", "_blank");
  };

  const onMailClicked = () => {
    window.open("mailto:info@deepsurface.ai", "_blank");
  };

  const desktopLayout = () => {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        className="footer-container"
      >
        <Grid item xs="auto">
          <Grid
            container
            direction="column"
            spacing={3}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs="auto" className="hipaa-footer-logo-container">
              <img
                src={"/hipaacompliant.png"}
                alt="hipaa-compliant-logo"
                className="footer-header-logo"
              />
            </Grid>
            <Grid item xs="auto" className="hipaa-footer-logo-container">
              <img
                src={"/pipedacompliant.webp"}
                alt="pipeda-compliant-logo"
                className="footer-header-logo"
              />
            </Grid>
            <Grid item xs="auto" className="privacy-footer-logo-container">
              <Typography onClick={onPolicyClicked} className="hoverable-text">
                Privacy Policy
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs="auto">
          <Grid
            container
            direction="column"
            spacing={1}
            className="footer-socials"
          >
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <LocationOnIcon />
              </Grid>
              <Grid item>
                <Typography variant="body2">Calgary, Alberta</Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <MailIcon onClick={onMailClicked} className="clickable-icon" />
              </Grid>
              <Grid item>
                <Typography
                  onClick={onMailClicked}
                  variant="body2"
                  className="hoverable-text"
                >
                  info@deepsurface.ai
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <LinkedInIcon
                  onClick={onLinkedinClicked}
                  sx={{ fontSize: 25 }}
                  className="clickable-icon"
                />
              </Grid>
              <Grid item>
                <Typography
                  onClick={onLinkedinClicked}
                  variant="body2"
                  className="hoverable-text"
                >
                  Stay connected with our team
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <InstagramIcon sx={{ fontSize: 25 }} />
              </Grid>
              <Grid item>
                <Typography variant="body2">Coming Soon</Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <FacebookIcon sx={{ fontSize: 25 }} />
              </Grid>
              <Grid item>
                <Typography variant="body2">Coming Soon</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs="auto">
          <Grid
            container
            direction="column"
            spacing={1}
            className="footer-menu"
          >
            <Grid item>
              <Typography
                variant="body2"
                onClick={onSurgeonClicked}
                className="footer-menu-text"
              >
                Professionals
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
                onClick={onPatientClicked}
                className="footer-menu-text"
              >
                Patients
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
                onClick={onAboutUsClicked}
                className="footer-menu-text"
              >
                About Us
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
                onClick={onBlogClicked}
                className="footer-menu-text"
              >
                Blog
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
                onClick={onContactUsClicked}
                className="footer-menu-text"
              >
                Request Demo
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={2} xs={1} lg={3} className="footer-logo-container">
          <img
            src={"/assets/DSAI-logo.png"}
            alt="company-logo"
            className="footer-header-logo"
            onClick={onLogoClicked}
          />
        </Grid>
      </Grid>
    );
  };

  const mobileLayout = () => {
    return (
      <Grid
        container
        direction="column"
        spacing={3}
        className="mobile-footer-container"
      >
        <Grid container direction="row" justifyContent="space-between">
          <Grid
            container
            direction="column"
            className="mobile-logo-policy-container"
          >
            <Grid item className="mobile-footer-header-logo">
              <img
                src={"/assets/DSAI-logo.png"}
                alt="company-logo"
                onClick={onLogoClicked}
              />
            </Grid>
            <Grid item className="mobile-privacy-footer-logo-container">
              <Typography onClick={onPolicyClicked} className="hoverable-text">
                Privacy Policy
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="mobile-footer-hipaa-logo">
          <img src={"/hipaacompliant.png"} alt="hipaa-compliant-logo" />
          <img src={"/pipedacompliant.webp"} alt="pipeda-compliant-logo" />        
        </Grid>
        <Grid item>
          <Typography variant="body2" onClick={onSurgeonClicked}>
            Professionals
          </Typography>
          <Typography variant="body2" onClick={onPatientClicked}>
            Patients
          </Typography>
          <Typography variant="body2" onClick={onAboutUsClicked}>
            About Us
          </Typography>
          <Typography variant="body2" onClick={onBlogClicked}>
            Blog
          </Typography>
          <Typography variant="body2" onClick={onContactUsClicked}>
            Request Demo
          </Typography>
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <LocationOnIcon />
            </Grid>
            <Grid item>
              <Typography variant="body2">Calgary, Alberta</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <MailIcon onClick={onMailClicked} />
            </Grid>
            <Grid item>
              <Typography onClick={onMailClicked} variant="body2">
                info@deepsurface.ai
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <LinkedInIcon onClick={onLinkedinClicked} />
            </Grid>
            <Grid item>
              <Typography onClick={onLinkedinClicked} variant="body2">
                Stay connected with our team
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <InstagramIcon />
            </Grid>
            <Grid item>
              <Typography variant="body2">Coming Soon</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <FacebookIcon />
            </Grid>
            <Grid item>
              <Typography variant="body2">Coming Soon</Typography>
            </Grid>
          </Grid>
          {/* <Grid container direction="row" spacing={2}>
            <Grid item>
              <PhoneIcon />
            </Grid>
            <Grid item>
              <Typography variant="body2">+1 123 456 7890</Typography>
            </Grid>
          </Grid> */}
        </Grid>
        {/* <Grid item>
          <Grid container direction="row" spacing={2}> */}
        {/* <Grid item>
              <LinkedInIcon onClick={onLinkedinClicked}/>
            </Grid>
            <Grid item>
              <FacebookIcon />
            </Grid>
            <Grid item>
              <InstagramIcon />
            </Grid> */}
        {/* <Grid item><TwitterIcon/></Grid> */}
        {/* </Grid>
        </Grid> */}
        <Grid item>
          <Typography variant="body2">(C) Deep Surface AI 2022</Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className="footer-container">
      {windowSize.width > 800 ? desktopLayout() : mobileLayout()}
    </div>
  );
};

export default Footer;
