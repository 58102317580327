import { Typography, useMediaQuery } from "@material-ui/core";
import Grid from '@mui/material/Grid';
import { useHistory } from "react-router-dom";
import "./styles.css";
import { MouseEventHandler } from "react";
import * as React from 'react';
 
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import { CardActionArea, CardActions } from '@mui/material';

const Blog = () => {
  const history = useHistory();

  const onPrideBlogPageClicked = () => {
    history.push("/pride-blog-page");
  }
  const onTimBlogPageClicked = () => {
    history.push("/tim-cruz-blog-page");
  }

  const onDavidKatzBlogPage = () => {
    history.push("/david-katz-blog-page");
  }

  interface BlogPostData {
      date: string,
      title: string,
      click:  MouseEventHandler<HTMLDivElement> | undefined,
      content: string,
      image: string,
      imageAlt: string,
      link: string
  }

    const data: BlogPostData[] = [
      {
        date: 'Nov 3, 2023',
        title: 'What is and is not AI in us, and us in AI',
        click: onDavidKatzBlogPage,
        content: 'CEO and co-founder, David Katz thinks out loud on the limits of people trying to mimic optimal AI strategies, the humanity of AI language model hallucinations, and where Deep Surface AI fits in rapidly evolving AI software universe.',
        image: '/team/david-k-headshot.png',
        imageAlt: 'davidKatz-blog-image',
        link: "",
      },
      {
        date: 'July 14, 2023',
        title: 'The Power of AI with Intent and Purpose',
        click: onTimBlogPageClicked,
        content: "A Collison YYC Podcast with Deep Surface AI's CTO, Tim Cruz.",
        image: '/blog/new-blog-img.jpeg',
        imageAlt: 'new-blog-img',
        link: '/tim-cruz-blog-page/',
      },
        {
        date: 'June 16, 2023',
        title: 'Celebrating Pride Month',
        click: onPrideBlogPageClicked,
        content: 'Deep Surface AI celebrates Pride Month...',
        image: '/blog/blog-img.jpg',
        imageAlt: 'blog-img',
        link: '/pride-blog-page/',
      },
      {
        date: 'Coming Soon',
        title: 'A New Research Paper from our Co-Founder',
        click: undefined,
        content: 'Co-founder, David Aponte, discusses an interactive syndrome atlas that provides a data-driven reference for the facial morphology of over 75 syndromes, allowing users to visualize syndromes at their desired age, sex, and severity.',
        image: '',
        imageAlt: '',
        link: '',
      },
      
      // Add more blog post data objects as needed
  ];
  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  const banner = () => {
      return (
        isSmallScreen ? (
          <Typography
            variant="h3"
            align="center"
            className="white-text"
          >
                What's New
              </Typography>
            ) : (
              <Typography variant="h1" align="center" className="white-text">
                What's New
              </Typography>
            )
      )
  }

  const newestPost = () => {
    return (<Card id="post-card" className="featured-post"  onClick={data[0].click}>
    <CardContent>
      <div className="post-content">
        <div>
          <Typography className="date" variant="h4">{data[0].date}</Typography>
          <Typography className="title" variant="h3">{data[0].title}</Typography>
          <Typography className="content" variant="body2">{data[0].content}</Typography>
          <a className="read-more" href={data[0].link} >
            <b>{"Read more >>"}</b>
          </a>
        </div>
          <img className="post-image" src={data[0].image} alt={data[0].imageAlt}  />
      </div>
    </CardContent>
  </Card>)
  }

  const rowPost = (data: BlogPostData[]) => {
    return (
    <Grid container spacing={2}>
    {data.slice(1).map((d) => (
      <Grid item xs={12} sm={6} md={4} key={d.title}>
        <Card id="row-card" style={{maxWidth: '500px',}} onClick={d.click}>
          <div className="card-item">
            <CardActionArea >
                <div className="card-image">
                  {d.image ? (
                    <CardMedia
                      className="card-media"
                      component="img"
                      image={d.image}
                      alt={d.imageAlt}
                    />
                  ) : (
                    <div className="no-image-yet">
                      <Typography variant="h3">
                        <span>{"Coming Soon"}</span>
                      </Typography>
                    </div>
                  )}
            
              </div>
            </CardActionArea>
            <CardContent id="card-content">
              <Typography id="card-content-title" gutterBottom variant="h5" component="div">
                {d.title}
              </Typography>
              <Typography variant="body2">
                {d.content}
              </Typography>
            </CardContent>
            {d.link &&
              <CardActions>
                <a className="read-more" id="row-card-read-more"href={data[0].link} >
                  <b>{"Read more >>"}</b>
                </a>
              </CardActions>
          }
          </div>
        </Card>
      </Grid>
    ))}
  </Grid>
  )}

  return (
    <div>
      <div>
        <div id="blog-header">
          <div id="banner">
             {banner()}
            <hr id="divider" />
          </div>
          <div >
            {newestPost()}
          </div>
        </div>
        <div id="blog-row">
            {rowPost(data)}  
        </div>
      </div>
    </div>
  );
};

export default Blog;
