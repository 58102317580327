import { Switch, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "./config";

const Router = () => {
    return (
        <div>
            <Header/>
            <Switch>
                {routes.map((routeItem) => {
                    return (
                        <Route
                            key={routeItem.key}
                            exact={routeItem.exact}
                            path={routeItem.path}
                            component={routeItem.component}
                        />
                    );
                })}
            </Switch>
            <Footer />
        </div>
    )
}

export default Router;