import { Tooltip, IconButton, Link, Grid, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useHistory } from 'react-router-dom';

export interface BlogPostData {
    title: string;
    date: string;

    image: string;
    imageAlt: string;
    link: any[];
}

interface Props {
    data: BlogPostData;
    children: ReactNode;
}

export const BlogPages = ({data, children}: Props) => {

  const history = useHistory();

  const onBlogClicked = () => {
    history.push("/blog");
  }  
  return (
    <div id="blog-page">
        <Tooltip title="Back">
          <IconButton id="back-button"
            onClick={onBlogClicked}>
            <ArrowBackIosNewIcon
              fontSize="large"
              sx={{ color: "#ffffff" }}
              />
          </IconButton>
        </Tooltip>
        <Grid container direction="column"  alignItems="center">
            <Grid container direction="row" justifyContent="center" id="blog-page-header-title">
                <Typography variant="h2" align="center" id="blog-page-header-text">
                    {data.title}
                </Typography>
            </Grid>

            {data.image && 
            <>
            <Grid item id="blog-img-container">
                <img src={data.image} alt={data.imageAlt} id="blog-img"/>
            </Grid>
            <Grid container direction="column" id="blog-document">
                {data.date && 
                <Grid item id="blog-document-date">
                    <Typography variant="body1" align="left" id="blog-document-header-text">
                        {data.date}
                    </Typography>
                </Grid>
                }
                    {children}
            </Grid>
            </>}
                {!data.image && 
                <>
                <Grid container direction="column" id="blog-document-with-noImage" style = {{paddingTop: 60}}>
                        {children}
                </Grid>
                </>}
        </Grid>
    </div>
  )
}

export default BlogPages