import { Typography, Grid, Button } from "@material-ui/core";
import { useHistory } from "react-router";
import useWindowSize from "../../hooks/useWindowSize";
import "./styles.css";

const SurgeonInfo = () => {
  const windowSize = useWindowSize();
  const history = useHistory();

  const onContactClicked = () => {
    history.push("/contact-us");
  };

  const desktopPageLayout = () => {
    return (
      <div>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          className="title-and-banner-container"
        >
          <Grid item className="title-text-container">
            <Typography variant="h1" align="center" className="white-text">
              Streamline surgical planning with custom 3D <br /> morphs for your
              patient's unique face.
            </Typography>
          </Grid>
          <Grid item className="button-container">
            <Button variant="outlined" onClick={onContactClicked}>
              Request a Demo
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="quote-div"
        >
          <Grid item xs={5}>
            <img
              src={"team/rahul-headshot.png"}
              alt="dr-rahul-seth"
              className="banner-picture"
            />
          </Grid>
          <Grid item xs={7}>
            <Grid container direction="column" justifyContent="center">
              {/* <Grid item>
                                <Typography className="begin-quotation-mark" variant="h1">
                                    “
                                </Typography>
                            </Grid> */}
              <Grid item>
                {windowSize.width > 1285 ? (
                  <Typography
                    className="quote"
                    align="center"
                    variant="h4"
                    style={{ fontSize: "25px" }}
                  >
                    "One of the hardest things about pre-op consultations is
                    understanding the patient's exact desires. They have an idea
                    of what they want, and I have an idea of what surgical
                    options are available to them but matching those
                    expectations can be challenging. Deep Surface AI’s software
                    efficiently streamlines those conversations. It allows the
                    patient and I to visually and effectively communicate
                    complex facial anatomy and surgical techniques."
                  </Typography>
                ) : (
                  <Typography className="quote" align="center" variant="body1">
                    "One of the hardest things about pre-op consultations is
                    understanding the patient's exact desires. They have an idea
                    of what they want, and I have an idea of what surgical
                    options are available to them but matching those
                    expectations can be challenging. Deep Surface AI's software
                    efficiently streamlines those conversations. It allows the
                    patient and I to visually and effectively communicate
                    complex facial anatomy and surgical techniques."
                  </Typography>
                )}
              </Grid>
              {/* <Grid container direction="row" justifyContent="flex-end">
                                <Grid item>
                                    <Typography className="end-quotation-mark" variant="h1">
                                        ”
                                    </Typography>
                                </Grid>
                            </Grid> */}
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography className="hyphen" variant="h1">
                    -
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className="quotee" variant="h4">
                    Dr. Rahul Seth, MD
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="title-text-div">
          <div className="divider-border" />
          <span className="divider-content">
            <Typography align="center" variant="h4">
              Align patient's desires with realistic surgical goals.
            </Typography>
          </span>
          <div className="divider-border" />
        </div>
        <Grid container direction="row" className="reasons-title-grid">
          <Grid item xs={4} className="div-right">
            <Typography
              align="center"
              variant="h4"
              className="reason-name-text"
            >
              FAST & SIMPLE
            </Typography>
          </Grid>
          <Grid item xs={4} className="div-both">
            <Typography
              align="center"
              variant="h4"
              className="reason-name-text"
            >
              SUPERIOR PLANNING
            </Typography>
          </Grid>
          <Grid item xs={4} className="div-left">
            <Typography
              align="center"
              variant="h4"
              className="reason-name-text"
            >
              EVIDENCE-BASED
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" className="reasons-grid">
          <Grid item xs={4} className="div-right">
            <Typography
              align="left"
              variant="body2"
              className="reason-description-text"
            >
              Realize your surgical vision in minutes. Our simple controls and
              comparison tools streamline your preoperative workflow. Our
              software automates facial morphing, while still enabling you to
              have precise control. Work in 3D and save time on morphing.
            </Typography>
          </Grid>
          <Grid item xs={4} className="div-both">
            <Typography
              align="left"
              variant="body2"
              className="reason-description-text"
            >
              We take the hassle out of morphing so that you can focus on your
              patient during preoperative consultations. Our 3D morphs bridge
              the gap between post-op expectations and practical goals for
              surgery, improving patient satisfaction.
            </Typography>
          </Grid>
          <Grid item xs={4} className="div-left">
            <Typography
              align="left"
              variant="body2"
              className="reason-description-text"
            >
              Our software seamlessly integrates facial anatomy and biological
              data into your aesthetic decision making. Combine your surgical
              expertise with evidence-based artificial intelligence to improve
              objectivity and align patient goals with realistic surgical
              outcomes.
            </Typography>
          </Grid>
        </Grid>
        <div className="title-text-div">
          <Typography align="center" variant="h4" className="video-title">
            Deep Surface AI Software Demonstration
          </Typography>
        </div>
        <div className="video-container">
          <iframe
            className="video"
            src="https://youtube.com/embed/K0JCgr0vGsI"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="DSAI-video"
          />{" "}
        </div>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className="booking-container"
          spacing={5}
        >
          <Grid item xs={6}>
            <Typography align="center" variant="h2" className="booking-text">
              Ready to upgrade your practice?
            </Typography>
          </Grid>
          <Grid item className="button-container">
            <Button variant="outlined" onClick={onContactClicked}>
              Request A Demo
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  };

  const mobilePageLayout = () => {
    return (
      <div>
        <Grid
          container
          direction="column"
          className="mobile-title-and-banner-container"
        >
          <Grid item className="mobile-title-text-container">
            {windowSize.width > 900 ? (
              <Typography variant="h1" align="center" className="white-text">
                Streamline surgical planning with custom 3D morphs for your
                patient's unique face.
              </Typography>
            ) : (
              <Typography variant="h3" align="center" className="white-text">
                Streamline surgical planning with custom 3D morphs for your
                patient's unique face.
              </Typography>
            )}
          </Grid>
          <Grid item className="button-container">
            <Button variant="outlined" onClick={onContactClicked}>
              Request A Demo
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          className="quote-div"
        >
          <Grid item>
            <img
              src={"surgeon/dr-seth.png"}
              alt="dr-seth"
              className="mobile-banner-picture"
            />
          </Grid>
          <Grid item>
            <Grid container direction="column" justifyContent="center">
              {/* <Grid item>
                                <Typography className="mobile-begin-quotation-mark" variant="h1">
                                    “
                                </Typography>
                            </Grid> */}
              <Grid item>
                {windowSize.width > 700 ? (
                  <Typography
                    className="mobile-quote"
                    align="center"
                    variant="h4"
                  >
                    "One of the hardest things about pre-op consultations is
                    getting inside the patient's mind. They have an idea of what
                    they want, and I have an idea of what surgical options are
                    available to them, and matching those expectations is a huge
                    challenge. Deep Surface AI’s software streamlines those
                    conversations. It allows the patient to visualize their
                    surgical opportunities, which helps to educate the patient
                    and myself, and creates more precision in surgical
                    planning."
                  </Typography>
                ) : (
                  <Typography
                    className="mobile-quote"
                    align="center"
                    variant="body1"
                  >
                    "One of the hardest things about pre-op consultations is
                    getting inside the patient's mind. They have an idea of what
                    they want, and I have an idea of what surgical options are
                    available to them, and matching those expectations is a huge
                    challenge. Deep Surface AI’s software streamlines those
                    conversations. It allows the patient to visualize their
                    surgical opportunities, which helps to educate the patient
                    and myself, and creates more precision in surgical
                    planning."
                  </Typography>
                )}
              </Grid>
              {/* <Grid container direction="row" justifyContent="flex-end">
                                <Grid item xs={2}>
                                    <Typography className="mobile-end-quotation-mark" variant="h1">
                                        ”
                                    </Typography>
                                </Grid>
                            </Grid> */}
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography className="hyphen" variant="h1">
                    -
                  </Typography>
                </Grid>
                <Grid item>
                  {windowSize.width > 700 ? (
                    <Typography className="quotee" variant="h3">
                      Dr. Rahul Seth, PhD, MD
                    </Typography>
                  ) : (
                    <Typography className="quotee" variant="h5">
                      Dr. Rahul Seth, PhD, MD
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="mobile-title-text-div">
          <div className="mobile-divider-border" />
          <span className="mobile-divider-content">
            <Typography align="center" variant="h4">
              Align patient’s desires with realistic surgical goals.
            </Typography>
          </span>
          <div className="mobile-divider-border" />
        </div>
        <Grid
          container
          direction="column"
          className="mobile-reasons-title-grid"
        >
          <Grid item className="div-right">
            <Typography
              align="center"
              variant="h3"
              className="reason-name-text"
            >
              FAST & SIMPLE
            </Typography>
            <Typography
              align="left"
              variant="body1"
              className="mobile-reason-description-text"
            >
              Realize your surgical vision in minutes. Our simple controls and
              comparison tools streamline your preoperative workflow. Our
              software automates facial morphing, while still enabling you to
              have precise control. Work in 3D and save time on morphing.
            </Typography>
          </Grid>
          <Grid item className="div-both">
            <Typography
              align="center"
              variant="h3"
              className="reason-name-text"
            >
              SUPERIOR PLANNING
            </Typography>
            <Typography
              align="left"
              variant="body1"
              className="mobile-reason-description-text"
            >
              We take the hassle out of morphing so that you can focus on your
              patient during preoperative consultations. Our 3D morphs bridge
              the gap between post-op expectations and practical goals for
              surgery, improving patient satisfaction.
            </Typography>
          </Grid>
          <Grid item className="div-left">
            <Typography
              align="center"
              variant="h3"
              className="reason-name-text"
            >
              EVIDENCE-BASED
            </Typography>
            <Typography
              align="left"
              variant="body1"
              className="mobile-reason-description-text"
            >
              Our software seamlessly integrates facial anatomy and biological
              data into your aesthetic decision making. Combine your surgical
              expertise with evidence-based artificial intelligence to improve
              objectivity and align patient goals with realistic surgical
              outcomes.
            </Typography>
          </Grid>
        </Grid>
        <div className="mobile-title-text-div">
          <div className="mobile-divider-border" />
          <span className="mobile-divider-content">
            <Typography align="center" variant="h4">
              Deep Surface AI Software Demonstration
            </Typography>
          </span>
          <div className="mobile-divider-border" />
        </div>
        <div className="mobile-video-container">
          <iframe
            className="mobile-video"
            src="https://youtube.com/embed/K0JCgr0vGsI"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="DSAI-video"
          />{" "}
        </div>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className="mobile-booking-container"
          spacing={5}
        >
          <Grid item>
            <Typography
              align="center"
              variant="h2"
              className="mobile-booking-text"
            >
              Ready to upgrade your practice?
            </Typography>
          </Grid>
          <Grid item className="mobile-button-container">
            <Button variant="outlined" onClick={onContactClicked}>
              Request A Demo
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  };
  return (
    <div>
      {windowSize.width > 1050 ? desktopPageLayout() : mobilePageLayout()}
    </div>
  );
};

export default SurgeonInfo;
