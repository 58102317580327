import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import Router from "./router/index";
import reportWebVitals from "./reportWebVitals";
import { hotjar } from "react-hotjar";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const THEME = createTheme({
  typography: {
    fontFamily: `"Montserrat", sans-serif`,
    h1: {
      fontSize: 50,
      fontWeight: 500,
      color: "#363636",
    },
    h2: {
      fontSize: 45,
      fontWeight: 500,
      color: "#363636",
      "@media (max-width: 500px)": {
        fontSize: 35,
        fontWeight: 500,
      },
    },
    h3: {
      fontSize: 30,
      fontWeight: 700,
      color: "#363636",
    },
    h4: {
      fontSize: 30,
      fontWeight: 500,
      color: "#363636",
      "@media (max-width: 500px)": {
        fontSize: 22,
        fontWeight: 500,
      },
    },
    h5: {
      fontSize: 22,
      fontWeight: 700,
      color: "#363636",
    },
    h6: {
      fontSize: 17,
      fontWeight: 700,
      color: "#363636",
    },
    body1: {
      fontSize: 22,
      fontWeight: 400,
      color: "#363636",
    },
    body2: {
      fontSize: 17,
      fontWeight: 400,
      color: "#363636",
    },
    button: {
      fontSize: 17,
      fontWeight: 700,
    },
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  overrides: {
    MuiButton: {
      outlined: {
        borderRadius: 69,
        color: "black",
        backgroundColor: "white",
        textTransform: "unset",
        borderColor: "black",
        boxShadow: "0 0 2px black",
        width: "187px !important",
        height: "62px",
      },
      contained: {
        borderRadius: 69,
        color: "white",
        backgroundColor: "black",
        textTransform: "unset",
        width: 120,
      },
    },
    MuiInputBase: {
      input: {
        fontSize: 17,
        fontWeight: 400,
      },
      root: {
        fieldset: {
          borderRadius: 40,
          borderColor: "black",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        fieldset: {
          borderRadius: "20px",
          borderColor: "black",
        },
      },
    },
  },
  palette: {
    "primary": {
      "main": "#33388f",
    },
  },
});

const App = () => {
  useEffect(() => {
    hotjar.initialize(3094429, 6);
  }, []);

  return (
    <div>
      <MuiThemeProvider theme={THEME}>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <BrowserRouter forceRefresh={true}>
          <Router />
        </BrowserRouter>
      </MuiThemeProvider>
    </div>
  );
};
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
