const Introduction = () => {
  return (
    <p>
      Deep Surface AI and our affiliates and subsidiaries (<b>"Company"</b> or
      <b>"we"</b> or
      <b>“us”</b>) respect your privacy and are committed to protecting it by
      complying with this policy (<b>“Policy”</b>). In this Policy, we use the
      word <b>“Subscriber”</b>
      to refer to anyone who has subscribed to and paid for use of our software
      (for example, a health clinic or health practitioner). We use the word
      <b>“you”</b> to refer to any individual user of our Services, such as a
      practitioner or staff member of a Subscriber, an Individual Contributor or
      an individual browsing or using our websites and web-based resources. We
      use the term <b>“Individual Contributors”</b> to refer to individuals who
      wish to contribute their data to our platform. We refer to our platform,
      [deepsurfaceai.com] (<b>“Website”</b>), and web-based resources,
      collectively, as the <b>“Services”</b>.
      <br />
      This Policy <b>applies</b> to information we collect, use, or disclose
      about you:
      <ul>
        <li>On this Website.</li>
        <li>
          In email, text, and other electronic messages between you and this
          Website.
        </li>
        <li>
          Through mobile or desktop (including handheld devices) applications
          you download from this Website, which provide dedicated
          non-browser-based interaction between you and this Website.{" "}
        </li>
        <li>
          When you interact with our advertising and applications on third-party
          websites and services if those applications or advertising include
          links to this Policy.
        </li>
      </ul>
      This Policy <b>does not</b> apply to information that:
      <ul>
        <li>
          We collect offline or through any other means, including through our
          affiliates or subsidiaries or third-party website (including our
          affiliates and subsidiaries).
        </li>
        <li>
          You provide to or is collected by any third party (including our
          affiliates and subsidiaries), through any application or content
          (including advertising) that may link to or be accessible from or on
          the Website.
        </li>
      </ul>
      Please read this Policy carefully to understand our policies and practices
      for collecting, processing, and storing your information. If you do not
      agree with our policies and practices, your choice is not to use our
      Website. By accessing or using this Website and the services we provide,
      you indicate that you understand, accept, and consent to the collection of
      your personal data and practices described in this Policy. This Policy may
      change from time to time (see Changes to Our Privacy Policy). You will
      receive a notice when we update this Policy to the email provided with
      your account. You may unsubscribe from the notice updates at any time.
    </p>
  );
};

const NoticeToPatients = () => {
  return (
    <p>
      If you are a patient of one of our Subscriber clinics or practitioners,
      your clinic or practitioner <b>(or, with respect to Ontario residents, the Custodian)</b> controls your patient information, including
      your contact information, billing details and patient records. Please
      contact your clinic or practitioner for any questions about your patient
      information. See the section titled <b>Patient Data</b> below for further
      information.
    </p>
  );
};

const Gdpr = () => {
  return (
    <p>
      For Personal information that is subject to the General Data Protection
      Regulation (GDPR), we rely on the following legal bases for collecting and
      using your Personal information:
      <ul>
        <li>Your consent</li>
        <li>
          Our legitimate interests (which are not overridden by your privacy
          rights), such as operating our business, understanding and improving
          our Services, direct marketing related to our Services, communicating
          with our Subscribers and users about our Services, events or related
          resources, improving our websites and protecting our legal rights and
          interests.
        </li>
      </ul>
      You may withdraw your consent at any time. Where we are using your
      Personal information for our legitimate interests, you have the right to
      object to that use. See below under <b>Your Rights</b> for how to withdraw
      consent or object. If you are a patient of one of our Subscriber clinics,
      please contact your clinic or practitioner if you have any questions about
      the legal basis for collecting and using your personal information. Our
      Subscribers may have a different legal basis for collecting and using a
      patient's personal information, such as providing health care or
      treatments as a regulated healthcare professional.
    </p>
  );
};

const InfoWeCollect = () => {
  return (
    <p>
      We collect and use several types of information from and about you,
      including:
      <ul>
        <li>
          <b>Personal information</b>, that we can reasonably use to directly or
          indirectly identify you, such as your name, mailing address, e-mail
          address, telephone number, Internet protocol (IP) address used to
          connect your computer to the Internet, username or other similar
          identifier, billing and account information, medical license number,
          clinic business name and address and any other identifier we may use
          to contact you ("personal information").
          <br />
          Additionally, for the purpose of providing our Services to a
          Subscriber and for the possibility of enhancing our database through
          Individual Contributors, we may process some or all the following
          sensitive information, but only with your express consent: age,
          gender, physical attributes, ethnicity or medical history, and any
          other data your provider may collect from you that would be deemed
          sensitive. Sensitive data in this context are the data of a special
          category within the meaning of <u>Article 9 (a)(2)a) GDPR</u>.
        </li>
        <li>
          <b>Non-personal information</b>, that does not directly or indirectly
          reveal your identity or directly relate to an identifiable individual,
          such as demographic information, or statistical or aggregated
          information. Statistical or aggregated data does not directly identify
          a specific person, but we may derive non-personal statistical or
          aggregated data from personal information. For example, we may
          aggregate personal information to calculate the percentage of users
          accessing a specific Website feature.
        </li>
        <li>
          <b>Technical information</b>, including your login information,
          browser type and version, time zone setting, browser plug-in types and
          versions, operating system and platform, or information about your
          internet connection, the equipment you use to access our Website, and
          usage details.
        </li>
        <li>
          <b>Non-personal details about your Website interactions</b>, including
          the full Uniform Resource Locators (URLs), clickstream to, through and
          from our Website (including date and time), products you viewed or
          searched for, page response times, download errors, length of visits
          to certain pages, page interaction information (such as scrolling,
          clicks, and mouse-overs), methods used to browse away from the page,
          or any phone number used to call our customer service number
        </li>
      </ul>
    </p>
  );
};

const PatientData = () => {
  return (
    <p>
      Subscribers use our Services to collect, store or process personal
      information from and about their patients and create patient records.
      These records may include a patient's name, address, health insurance and
      billing information, medical charts, appointment history and other patient
      data (<b>“Patient Data”</b>). This information is sometimes referred to as
      “personal health information”, “protected health information”, “data
      concerning health” or “sensitive data” depending on the location of the
      Subscribers and the privacy laws applicable to them. If you are a patient,
      Patient Data is collected from you when you visit your Subscriber clinic
      or practitioner. Subscribers retain sole control over Patient Data and may
      be referred to as a “health information custodian”, a “covered entity”,
      a “controller” <b>or a "Custodian"</b>depending on their location and the privacy laws applicable
      to them.
      <br />
      Subscribers determine:
      <ul>
        <li>What Patient Data to collect;</li>
        <li>How the Subscriber will use the Patient Data;</li>
        <li>Who has access to Patient Data;</li>
        <li>How long the Subscriber will store Patient Data; and</li>
        <li>On what basis the Subscriber may delete Patient Data.</li>
      </ul>
      Subscribers are responsible for complying with laws and regulations
      governing the use of Patient Data when using our Website, and for
      determining the legal basis for such use.
    </p>
  );
};

const HowWeCollect = () => {
  return (
    <p>
      We use different methods to collect your information, including through:
      <ul>
        <li>
          <b>Direct interactions with you when you provide it to us</b>, for
          example, by filling in forms or corresponding with us by phone, email,
          or otherwise.
        </li>
        <li>
          <b>Individual Contributions</b>. You may also provide information for
          us to publish or display on public Website areas or transmit to other
          Website users or third parties.
        </li>
        <li>
          <b>Automated technologies or interactions</b>, as you navigate through
          our Website. Information collected automatically may include usage
          details, IP addresses, and information collected through cookies, web
          beacons, and other tracking technologies.
        </li>
        <li>
          <b>Third parties or publicly available sources</b>, for example, our
          business partners.
        </li>
      </ul>
      <b>Information You Provide to the Company</b>
      <br />
      The information we collect directly from you on or through our Website may
      include:
      <ul>
        <li>
          Information that you provide by filling in forms on our Website. This
          includes information provided at the time of registering to use our
          Website, subscribing to our Service, posting material, and / or
          requesting further services.
        </li>
        <li>
          Records and copies of your correspondence (including email addresses)
          if you contact us.
        </li>
        <li>
          Your responses to surveys that we might ask you to complete for
          research purposes.
        </li>
        <li>Your search queries on the Website.</li>
      </ul>
      <b>
        Information We Collect Through Cookies and Other Automatic Data
        Collection Technologies
      </b>
      <br />
      As you navigate through and interact with our Website, we may use cookies
      or other automatic data collection technologies to collect certain
      information about your equipment, browsing actions, and patterns,
      including:
      <ul>
        <li>
          Details of your visits to our Website, including traffic data,
          location data, logs, and other communication data and the resources
          that you access and use on the Website.
        </li>
        <li>
          Information about your computer and internet connection, including
          your IP address, operating system, and browser type.
        </li>
      </ul>
      We may also use these technologies to collect information about your
      online activities over time and across third-party websites or other
      online services (behavioral tracking). To learn more or to opt-out of
      tailored advertising please notify us.
      <br />
      The information we collect automatically is statistical data is
      statistical information and may include personal informationand we may
      maintain it or associate it with personal information we collect in other
      ways, that you provide to us, or receive from third parties. We only
      collect this information to helps us improve our Website and to deliver a
      better and more personalized service, including by enabling us to:
      <ul>
        <li>Estimate our user size and usage patterns.</li>
        <li>
          Store information about your preferences, allowing us to customize our
          Website according to your individual interests.
        </li>
        <li>Speed up your searches.</li>
        <li>Recognize you when you return to our Website.</li>
      </ul>
      The technologies we use for this automatic data collection may include:
      <ul>
        <li>
          <b>Cookies (or browser cookies)</b>. A cookie is a small file placed
          on the hard drive of your computer. You may refuse to accept browser
          cookies by activating the appropriate setting on your browser.
          However, if you select this setting you may be unable to access
          certain parts of our Website. Unless you have adjusted your browser
          setting so that it will refuse cookies, our system will issue cookies
          when you direct your browser to our Website.
        </li>
        <li>
          <b>Flash Cookies</b>. Certain features of our Website may use local
          stored objects (or Flash cookies) to collect and store information
          about your preferences and navigation to, from, and on our Website.
          Flash cookies are not managed by the same browser settings that are
          used for browser cookies. For information about managing your privacy
          and security settings for Flash cookies, see Choices About How We Use
          and Disclose Your Information.
        </li>
        <li>
          <b>Web Beacons</b>. Pages of our Website and our e-mails may contain
          small electronic files known as web beacons (also referred to as clear
          gifs, pixel tags, and single-pixel gifs) that permit the Company, for
          example, to count users who have visited those pages or opened an
          email and for other related website statistics (for example, recording
          the popularity of certain website content and verifying system and
          server integrity).
        </li>
      </ul>
      <b>Third Party Use of Cookies</b>
      <br />
      The Website may include links to third-party websites, plug-ins, services,
      social networks, or applications. Clicking on those links or enabling
      those connections may allow the third party to collect or share data about
      you. If you follow a link to a third-party website or engage a third-party
      plugin, please note that these third parties have their own privacy
      policies and we do not accept any responsibility or liability for these
      policies.
      <br />
      We do not control these third-party websites, and we encourage you to read
      the privacy policy of every website you visit. If you have any questions
      about an advertisement or other targeted content, you should contact the
      responsible provider directly. For more information about how you can opt
      out of receiving targeted advertising from many providers, see Choices
      About How We Use and Disclose Your Information.
    </p>
  );
};

const HowWeUse = () => {
  return (
    <p>
      We use information that we collect about you or that you provide to us,
      including any Personal information:
      <ul>
        <li>To present our Services to you.</li>
        <li>
          To provide you with information, products, or services that you
          request from us.
        </li>
        <li>
          To fulfill the purposes for which you provided the information or that
          were described when it was collected, or any other purpose for which
          you provide it.
        </li>
        <li>
          To provide you with notices about your account and/or subscription,
          including expiration and renewal notices.
        </li>
        <li>
          To carry out our obligations and enforce our rights arising from any
          contracts with you, including for billing and collection or to comply
          with legal requirements.
        </li>
        <li>
          To notify you about changes to our Services, including the Website.
        </li>
        <li>For any other purpose with your consent.</li>
      </ul>
      We may also use your contact information for marketing purposes such as
      promotional emails, direct mail, and sales contacts. You can opt-out of
      our marketing communications at any time by unsubscribing or contacting us
      at <a href="mailto:privacy@deepsurfaceai.com">privacy@deepsurfaceai.com</a>.
      Please note that the Company does not collect or manage the contact
      information of patients, or any marketing or other communications between
      a Subscriber and its patients.
    </p>
  );
};

const Disclosure = () => {
  return (
    <p>
      We do not sell or distribute personal information to third parties for
      their own commercial or marketing purposes. We will only share personal
      information we collect in the following circumstances:
      <ul>
        <li>To our subsidiaries and affiliates.</li>
        <li>
          In accordance with applicable law, to a buyer or other successor in
          the event of a merger, divestiture, restructuring, reorganization,
          dissolution, or other sale or transfer of some or all of the Company's
          assets, whether as a going concern or as part of bankruptcy,
          liquidation, or similar proceeding, in which personal information held
          by the Company about our customers and users is among the assets
          transferred.
        </li>
        <li>
          To advertisers and advertising networks that require the information
          to select and serve relevant advertisements to you and others. We do
          not disclose data about identifiable individuals to our advertisers,
          but we may provide them with aggregate information about our users
          (for example, we may inform them that 400 women between 30 and 45 have
          clicked on their advertisement on a specific day). We may also use
          such aggregate information to help advertisers target a specific
          audience (for example, men in a specific location). We may make use of
          the personal information we have collected from you to enable us to
          display our advertisers' advertisements to that target audience.
        </li>
        <li>
          To third parties to market their products or services to you if you
          have consented to these disclosures. We contractually require these
          third parties to keep personal information confidential and use it
          only for the purposes for which we disclose it to them. For more
          information, see Choices About How We Use and Disclose Your
          Information.
        </li>
        <li>
          To contractors, service providers, and other third parties we use to
          support our business (such as analytics and search engine providers
          that assist us with Website improvement and optimization) and who are
          contractually obligated to keep Personal information confidential, use
          it only for the purposes for which we disclose it to them, and to
          process the Personal information with the same standards set out in
          this Policy.
        </li>
        <li>
          To fulfill the purpose for which you provide it. For example, if you
          give us an email address to use the "email my records" feature of our
          Website, we will transmit the contents of that email and your email
          address to the recipient email.
        </li>
        <li>
          For any other purpose disclosed by us when you provide the
          information.
        </li>
        <li>With your consent.</li>
      </ul>
      We may also disclose Personal information:
      <ul>
        <li>
          To comply with any court order, law, or legal process, including to
          respond to any government or regulatory request, in accordance with
          applicable law.
        </li>
        <li>
          To enforce or apply our terms of use and other agreements, including
          for billing and collection purposes.
        </li>
        <li>
          If we believe disclosure is necessary or appropriate to protect the
          rights, property, or safety of the Company, our users, or others. This
          includes exchanging information with other companies and organizations
          for the purposes of fraud protection and credit risk reduction.
        </li>
      </ul>
    </p>
  );
};

const TransferringInfo = () => {
  return (
    <p>
      Personal information may be transferred to and processed within Canada or
      the United States. Before transferring your personal information, we
      ensure that appropriate safeguards are in place and that your privacy
      rights are protected and preserved. Such safeguards may include the
      existence of an EU adequacy decision, adherence to the Swiss-US Privacy
      Shield Frameworks, the Standard Contractual Clauses approved by the
      European Commission, binding corporate rules, or other legal mechanisms to
      safeguard the personal information being transferred.
      <br />
      By submitting your personal information or engaging with the Website, you
      consent to this transfer, storage, or processing.
      <br />
      We also transfer data to the third-party service providers described who
      we use to provide our Services. Their use is limited to the service
      indicated, including:
      <br />
      <br />
      <table style={{ width: "40%", border: "1px solid" }}>
        <tr style={{ border: "1px solid" }}>
          <th>Entity Name</th>
          <th>Purpose</th>
          <th>Entity Country</th>
        </tr>
        <tr>
          <td>Amazon Web Services, Inc.</td>
          <td>Cloud Service Provider</td>
          <td>United States</td>
        </tr>
        <tr>
          <td>Google Inc.</td>
          <td>Cloud Service Provider</td>
          <td>United States</td>
        </tr>
      </table>
    </p>
  );
};

const ChoicesAboutInfo = () => {
  return (
    <p>
      We strive to provide you with choices regarding the personal information
      you provide to us. We have created mechanisms to provide you with the
      following control over your information:
      <ul>
        <li>
          <b>Tracking Technologies and Advertising</b>. You can set your browser
          to refuse all or some browser cookies, or to alert you when cookies
          are being sent. To learn how you can manage your Flash cookie
          settings, visit the Flash player settings page on Adobe's website. If
          you disable or refuse cookies, please note that some parts of this
          Website may not be accessible or may not function properly.
        </li>
        <li>
          <b>Third-Party Advertising</b>. If you do want us to share your
          personal information with unaffiliated or non-agent third parties for
          promotional purposes, you can opt-in by checking the relevant box
          located on the form where we collect your data (the registration
          form). Alternatively, you wish to opt-out, you can do so by logging
          into the Website and adjusting your user preferences in your account
          profile by checking or unchecking the relevant boxes or by sending us
          an email stating your request to{" "}
          <a href="mailto:privacy@deepsurfaceai.com">privacy@deepsurfaceai.com</a>.
        </li>
        <li>
          <b>Promotional Offers from the Company</b>. If you have opted in to
          receive certain emails from us but no longer wish to have your contact
          information used by the Company to promote our own or third parties'
          products or services, you can opt-out by sending us an email stating
          your request to{" "}
          <a href="mailto:privacy@deepsurfaceai.com">privacy@deepsurfaceai.com</a> or
          at any other time by logging into the Website and adjusting your user
          preferences in your account profile by checking or unchecking the
          relevant boxes. If we have sent you a promotional email, you may
          unsubscribe by clicking the unsubscribe link we have included in the
          email. This opt-out does not apply to information provided to the
          Company as part of a product purchase, warranty registration, product
          service experience, or other transactions.
        </li>
        <li>
          <b>Targeted Advertising</b>. If you do not want us to use information
          that we collect or that you provide to us to deliver advertisements
          according to our advertisers' target-audience preferences, you can opt
          out by sending us an email stating your request to{" "}
          <a href="mailto:privacy@deepsurfaceai.com">privacy@deepsurfaceai.com</a>.
        </li>
      </ul>
    </p>
  );
};

const DataSecurity = () => {
  return (
    <p>
      We protect your personal information, including Patient Data stored in our
      platform, by:
      <ul>
        <li>
          Using industry standard security controls such as encryption and an
          SSL (Secured Sockets Layers) certificate to ensure information is
          transmitted over a secured connection between your browser and our web
          server.
        </li>
        <li>
          Using state-of-the-art data centers with appropriate security and
          compliance certifications, such as SOC 2 and transfer mechanisms that
          are HIPAA compliant.
        </li>
        <li>
          Having our personnel sign strict confidentiality agreements to ensure
          they understand the confidential nature of the data we process, and
          only accessing your account when you request assistance from us.
        </li>
        <li>
          Requiring password protection of your user account with a password set
          by you. We cannot access or identify your password. The only way to
          recover a password is for you to initiate a reset via the email
          address or mobile phone number you use for the Services.
        </li>
      </ul>
      The safety and security of your information also depends on you. Where we
      have given you (or where you have chosen) a password for access to certain
      parts of our Website, you are responsible for keeping this password
      confidential. We ask you not to share your password with anyone.
      <br />
      <br />
      Unfortunately, the transmission of information via the Internet is not
      completely secure. Although we do our best to protect your personal
      information, we cannot guarantee the security of your personal information
      transmitted to our Website. Any transmission of personal information is at
      your own risk. We are not responsible for circumvention of any privacy
      settings or security measures contained on the Website.
    </p>
  );
};

const DataRetention = () => {
  return (
    <p>
      Except as otherwise permitted or required by applicable law or regulation,
      we will only retain your personal information for as long as necessary to
      fulfill the purposes we collected it for (if you are an Individual
      Contributor, only for as long as you consent to at the time of
      collection), including for the purposes of satisfying any legal,
      accounting, or reporting requirements. Under some circumstances we may
      anonymize your personal information so that it can no longer be associated
      with you. We reserve the right to use such anonymous and de-identified
      data for any legitimate business purpose without further notice to you or
      your consent.
      <br />
      <br />
      If you are a patient of one of our Subscriber clinics, please contact your
      clinic or practitioner for information regarding the storage period for
      your Patient Data.
    </p>
  );
};

const Children = () => {
  return (
    <p>
      Our Website is not intended for children under 16 years of age. No one
      under age of 18 may provide any personal information to or on the Website
      without parental or legal guardian consent. We do not knowingly collect
      personal information from children under 18. If we learn we have collected
      or received personal information from a child under 18, without
      verification of parental or legal guardian consent, we will delete that
      information. If you believe we might have any information from or about a
      child under 18, please contact us at{" "}
      <a href="mailto:privacy@deepsurfaceai.com">privacy@deepsurfaceai.com</a>.
    </p>
  );
};

const YourRights = () => {
  return (
    <p>
      Individuals have certain rights with respect to their personal
      information. These rights are set out below. You may contact us at{" "}
      <a href="mailto:privacy@deepsurfaceai.com">privacy@deepsurfaceai.com</a> to
      exercise your rights at any time. If you are a patient of one of our
      Subscriber clinics, please contact your clinic or practitioner to exercise
      any of these rights with respect to your Patient Data.
      <ul>
        <li>
          <b>Correction and Deletion</b>. We will make reasonable efforts to
          ensure that the personal information we collect from you is accurate
          and complete. You may update, correct or delete your account
          information at any time by logging into your user account and
          modifying your personal information, including your preferences to
          receive messages from us. Please note, we cannot delete your personal
          information except by also deleting your user account.
        </li>
        <li>
          <b>Withdrawing Consent</b>. Where you have provided your consent to
          the collection, use and transfer of your personal information, you
          have the right to withdraw that consent at any time. In addition, all
          our marketing email messages contain the ability to automatically
          “opt-out” or unsubscribe from our mailing lists and marketing
          messages.
        </li>
        <li>
          <b>Access and Portability</b>. You have the right to request a record
          of the personal information that we have collected about you and to
          ask that the information be provided in a structured, used electronic
          format (where applicable and technically feasible). There may be some
          cases where we cannot provide you with certain information about you
          if it would mean disclosure of personal information of another person
          or other confidential information, or if it would compromise our
          security systems. We will respond to you within thirty (30) days of
          receiving your request. We may charge a fee where permitted by
          applicable law.
        </li>
        <li>
          <b>Restriction and Objection</b>. In certain limited circumstances,
          individuals in the EU may request that we restrict our use of their
          personal information and, where we rely on legitimate interests as the
          legal basis for using your personal information, you have the right to
          object to such use. In these cases, we can be required to no longer
          use your personal information; however, this may mean that certain
          components of our Services cannot be made available to you.
        </li>
        <li>
          <b>Complaints</b>. You have the right to lodge a complaint with a
          supervisory authority (i.e., the independent public authority
          responsible for monitoring data protection laws in your jurisdiction).
          You may also contact the Privacy Commissioner of Canada (for
          international matters and inter-provincial matters) (
          <a href="http://www.priv.gc.ca/">http://www.priv.gc.ca/</a>).
        </li>
      </ul>
    </p>
  );
};

const ChangesToPolicy = () => {
  return (
    <p>
      It is the Company's policy to post any changes we make to the Policy on
      this page. If we make material changes to how we treat our users' personal
      information, we will notify you by email to the email address specified in
      your account.
      <br />
      <br />
      We include the date the privacy policy was last revised at the top of the
      page. You are responsible for ensuring we have an up-to-date, active, and
      deliverable email address for you, and for periodically visiting our
      Website and this Policy to check for any changes.
      <br />
      <br />
      You may also subscribe here to receive automatic notifications of updates
      to this Policy.
    </p>
  );
};

const Contact = () => {
  return (
    <p>
      If you have any questions or concerns about our Privacy Policy and our
      privacy practices, please contact us at{" "}
      <a href="mailto:privacy@deepsurfaceai.com">privacy@deepsurfaceai.com</a>.
    </p>
  );
};

export const policy: { [key: string]: any } = {
  Introduction: <Introduction />,
  "Notice to Patients": <NoticeToPatients />,
  "GDPR EU/UK and Legal Basis": <Gdpr />,
  "Information We Collect About You": <InfoWeCollect />,
  "Patient Data": <PatientData />,
  "How We Collect Information About You": <HowWeCollect />,
  "How We Use Your Information": <HowWeUse />,
  "Disclosure of Your Information": <Disclosure />,
  "Transferring Your Personal Information": <TransferringInfo />,
  "Choices About How We Use and Disclose Your Information": (
    <ChoicesAboutInfo />
  ),
  "Data Security": <DataSecurity />,
  "Data Retention": <DataRetention />,
  "Children Under the Age of 13": <Children />,
  "Your Rights": <YourRights />,
  "Changes to Our Privacy Policy": <ChangesToPolicy />,
  "Contact Information and Challenging Compliance": <Contact />,
};
