import {Grid, Typography } from "@material-ui/core";
// import { useHistory } from "react-router";
import useWindowSize from "../../hooks/useWindowSize";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import "./styles.css";

const AboutUs = () => {
    // const history = useHistory();
    const windowSize = useWindowSize();

    const onLinkedinClicked = () => {
        window.open("https://www.linkedin.com/company/deep-surface-ai/", '_blank')
    }

    const onMemberClicked = (member: string) =>{
        const profiles: any = {
            "katz": "https://www.linkedin.com/in/david-katz-dsai/",
            "seth": "https://www.linkedin.com/in/rahul-seth-a889011a6/",
            "cruz": "https://www.linkedin.com/in/tim-cruz-34a11489/",
            "aponte": "https://www.linkedin.com/in/jose-aponte-100420233/",
            "ayadiuno": "https://www.linkedin.com/in/chidinma-ayadiuno/",
            "bregazzi": "https://www.linkedin.com/in/bellabregazzi/",
            "fream": "https://www.linkedin.com/in/tom-fream/",
            "muli": "https://www.linkedin.com/in/lisa-muli/",
            "neves": "https://www.linkedin.com/in/amanda-neves-054371226/",
            "pilz": "https://www.linkedin.com/in/julie-pilz/",
            "vogeli": "https://www.linkedin.com/in/zoe-vogeli/",
            "kevadiya": "https://www.linkedin.com/in/vishrut-kevadiya-57a69a126/",
            "glover": "https://www.linkedin.com/in/margaretglovercampbell/"
        };
        window.open(profiles[member], '_blank')
    }

    const desktopPageLayout = () => {
        return(
            <div>
                <Grid container direction="column" alignItems="center" alignContent="center" className="main-content-container" spacing={9}>
                    <Grid item className="title-text-container">
                        <Typography variant="h1" align="center" className="vision-statement-text">
                            Meet our team.
                        </Typography>
                        <hr className="vision-statement-divider"/>
                    </Grid>
                    <Grid container className="all-members-container" direction="column" justifyContent="center">
                        <Grid container className="first-members-container" direction="row" justifyContent="center">
                            <Grid item xs={3} className="member-container">
                                <Grid container direction="column" alignItems="center">
                                    <Grid item >
                                        <img src={"team/david-k-headshot.webp"} className="member-picture" alt="david-katz"/>
                                    </Grid>
                                    <Grid container direction="row" justifyContent="center" className="hover" onClick={() => onMemberClicked("katz")}>
                                        <LinkedInIcon className="linkedin-icon"/>
                                        <Typography align="center" variant="h6" className="member-name">
                                            David Katz
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="center" variant="body2">
                                            (He/Him)
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="center" variant="body2">
                                            Pres, CEO
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} className="member-container">
                                <Grid container direction="column" alignItems="center" >
                                    <Grid item >
                                        <img src={"team/rahul-headshot.webp"} className="member-picture" alt="rahul-seth"/>
                                    </Grid>
                                    <Grid container direction="row" justifyContent="center" className="hover" onClick={() => onMemberClicked("seth")}>
                                        <LinkedInIcon className="linkedin-icon"/>
                                        <Typography align="center" variant="h6" className="member-name">
                                            Rahul Seth
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="center" variant="body2">
                                            (He/Him)
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="center" variant="body2">
                                            CMO
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} className="member-container">
                                <Grid container direction="column" alignItems="center">
                                    <Grid item >
                                        <img src={"team/Tim-headshot.webp"} className="member-picture" alt="tim-cruz"/>
                                    </Grid>
                                    <Grid container direction="row" justifyContent="center" className="hover" onClick={() => onMemberClicked("cruz")}>
                                        <LinkedInIcon className="linkedin-icon"/>
                                        <Typography align="center" variant="h6" className="member-name">
                                            Tim Cruz
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="center" variant="body2">
                                            (He/Him)
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="center" variant="body2">
                                            CTO
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} className="member-container">
                                <Grid container direction="column" alignItems="center">
                                    <Grid item >
                                        <img src={"team/margaret-headshot.webp"} className="member-picture" alt="margaret-glover"/>
                                    </Grid>
                                    <Grid container direction="row" justifyContent="center" className="hover" onClick={() => onMemberClicked("glover")}>
                                        <LinkedInIcon className="linkedin-icon"/>
                                        <Typography align="center" variant="h6" className="member-name">
                                            Margaret Glover
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="center" variant="body2">
                                            (She/Her)
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="center" variant="body2">
                                            COO
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className="second-members-container" direction="row" justifyContent="center">
                            <Grid item xs={3} className="member-container">
                                <Grid container direction="column" alignItems="center">
                                    <Grid item >
                                        <img src={"team/david-a-headshot.webp"} className="member-picture" alt="david-aponte"/>
                                    </Grid>
                                    <Grid container direction="row" justifyContent="center" className="hover" onClick={() => onMemberClicked("aponte")}>
                                        <LinkedInIcon className="linkedin-icon"/>
                                        <Typography align="center" variant="h6" className="member-name">
                                            David Aponte
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="center" variant="body2">
                                            (He/Him)
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="center" variant="body2">
                                            Head of AI
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} className="member-container">
                                <Grid container direction="column" alignItems="center" >
                                    <Grid item >
                                        <img src={"team/chidinma-headshot.webp"} className="member-picture" alt="chidinma-ayadiuno"/>
                                    </Grid>
                                    <Grid container direction="row" justifyContent="center" className="hover" onClick={() => onMemberClicked("ayadiuno")}>
                                        <LinkedInIcon className="linkedin-icon"/>
                                        <Typography align="center" variant="h6" className="member-name">
                                            Chidinma Ayadiuno
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="center" variant="body2">
                                            (She/Her)
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="center" variant="body2">
                                            Compliance, Ops
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} className="member-container">
                                <Grid container direction="column" alignItems="center">
                                    <Grid item >
                                        <img src={"team/tom-headshot.webp"} className="member-picture" alt="tom-fream"/>
                                    </Grid>
                                    <Grid container direction="row" justifyContent="center" className="hover" onClick={() => onMemberClicked("fream")}>
                                        <LinkedInIcon className="linkedin-icon"/>
                                        <Typography align="center" variant="h6" className="member-name">
                                            Tom Fream
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="center" variant="body2">
                                            (He/Him)
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="center" variant="body2">
                                            Software
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} className="member-container">
                                <Grid container direction="column" alignItems="center">
                                    <Grid item >
                                        <img src={"team/vish-headshot.webp"} className="member-picture" alt="vishrut--kevadiya"/>
                                    </Grid>
                                    <Grid container direction="row" justifyContent="center" className="hover" onClick={() => onMemberClicked("kevadiya")}>
                                        <LinkedInIcon className="linkedin-icon"/>
                                        <Typography align="center" variant="h6" className="member-name">
                                            Vishrut Kevadiya
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="center" variant="body2">
                                            (He/Him)
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="center" variant="body2">
                                            Software (Intern)
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className="third-members-container" direction="row" justifyContent="center">
                            <Grid item xs={3} className="member-container">
                                <Grid container direction="column" alignItems="center" >
                                    <Grid item >
                                        <img src={"team/amanda-headshot.webp"} className="member-picture" alt="amanda-neves"/>
                                    </Grid>
                                    <Grid container direction="row" justifyContent="center" className="hover" onClick={() => onMemberClicked("neves")}>
                                        <LinkedInIcon className="linkedin-icon"/>
                                        <Typography align="center" variant="h6" className="member-name">
                                            Amanda Neves
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="center" variant="body2">
                                            (They/Them)
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="center" variant="body2">
                                            AI, 3D
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} className="member-container">
                                <Grid container direction="column" alignItems="center">
                                    <Grid item >
                                        <img src={"team/zoe-headshot.webp"} className="member-picture" alt="zoe-vogeli"/>
                                    </Grid>
                                    <Grid container direction="row" justifyContent="center" className="hover" onClick={() => onMemberClicked("vogeli")}>
                                        <LinkedInIcon className="linkedin-icon"/>
                                        <Typography align="center" variant="h6" className="member-name">
                                            Zoe Vogeli
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="center" variant="body2">
                                            (She/Her)
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="center" variant="body2">
                                            Commercialization
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={7} className="blurb-text-container">
                        <Typography variant="body2" className="blurb-text">
                        At Deep Surface AI, our mission is to make 3D planning software that works like a natural extension of your medical aesthetics expertise. We’ve built surgical best practices, data-driven AI, and elegant feature design into our morphing tools to provide you and your patients with the ideal platform for visualizing and selecting aesthetics objectives.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container direction="column" spacing={4} alignItems="center" className="call-to-action-banner">
                    <Grid item>
                        <Typography variant="h2" align="center" className="call-to-action-text">
                            Stay connected with <br /> our team.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <LinkedInIcon  sx={{fontSize: 60}} className="linkedin-icon" onClick={onLinkedinClicked}/>
                    </Grid>
                </Grid>
            </div>
        )
    }

    const mobilePageLayout = () => {
        return(
            <div>
                <Grid container direction="column" alignItems="center" alignContent="center" className="mobile-main-content-container" spacing={6}>
                    <Grid item/>
                    <Grid item xs={10}>
                        <Typography variant="h3" align="center" className="mobile-vision-statement-text">
                            Meet our team. 
                        </Typography>
                        <hr className="mobile-vision-statement-divider"/>
                    </Grid>
                    <Grid container className="mobile-all-members-container" direction="column" justifyContent="center" spacing={2}>
                        <Grid item xs="auto" className="mobile-member-container">
                            <Grid container direction="column" spacing={1} alignItems="center">
                                <Grid item >
                                    <img src={"team/david-k-headshot.webp"} className="mobile-member-picture" alt="david-katz" onClick={() => onMemberClicked("katz")}/>
                                </Grid>
                                <Grid container direction="row" justifyContent="center" className="hover">
                                    <LinkedInIcon className="linkedin-icon"/>
                                    <Typography align="center" variant="h6">
                                        David Katz (He/Him)
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography align="center" variant="body2" className="member-title-text">
                                        Pres, CEO
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs="auto" className="mobile-member-container">
                            <Grid container direction="column" spacing={1} alignItems="center">
                                <Grid item >
                                    <img src={"team/rahul-headshot.webp"} className="mobile-member-picture" alt="rahul-seth" onClick={() => onMemberClicked("seth")}/>
                                </Grid>
                                <Grid container direction="row" justifyContent="center" className="hover">
                                    <LinkedInIcon className="linkedin-icon"/>
                                    <Typography align="center" variant="h6">
                                        Rahul Seth (He/Him)
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography align="center" variant="body2" className="member-title-text">
                                        CMO
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs="auto" className="mobile-member-container">
                            <Grid container direction="column" spacing={1} alignItems="center">
                                <Grid item >
                                    <img src={"team/Tim-headshot.webp"} className="mobile-member-picture" alt="tim-cruz" onClick={() => onMemberClicked("cruz")}/>
                                </Grid>
                                <Grid container direction="row" justifyContent="center" className="hover">
                                    <LinkedInIcon className="linkedin-icon"/>
                                    <Typography align="center" variant="h6">
                                        Tim Cruz (He/Him)
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography align="center" variant="body2" className="member-title-text">
                                        CTO
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs="auto" className="mobile-member-container">
                            <Grid container direction="column" spacing={1} alignItems="center">
                                <Grid item >
                                    <img src={"team/margaret-headshot.webp"} className="mobile-member-picture" alt="margaret-glover" onClick={() => onMemberClicked("glover")}/>
                                </Grid>
                                <Grid container direction="row" justifyContent="center" className="hover">
                                    <LinkedInIcon className="linkedin-icon"/>
                                    <Typography align="center" variant="h6">
                                        Margaret Glover (She/Her)
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography align="center" variant="body2" className="member-title-text">
                                        COO
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs="auto" className="mobile-member-container">
                            <Grid container direction="column" spacing={1} alignItems="center">
                                <Grid item >
                                    <img src={"team/david-a-headshot.webp"} className="mobile-member-picture" alt="david-aponte" onClick={() => onMemberClicked("aponte")}/>
                                </Grid>
                                <Grid container direction="row" justifyContent="center" className="hover">
                                    <LinkedInIcon className="linkedin-icon"/>
                                    <Typography align="center" variant="h6">
                                        David Aponte (He/Him)
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography align="center" variant="body2" className="member-title-text">
                                        Head of AI
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs="auto" className="mobile-member-container">
                            <Grid container direction="column" spacing={1} alignItems="center">
                                <Grid item >
                                    <img src={"team/chidinma-headshot.webp"} className="mobile-member-picture" alt="chidinma-ayadiuno" onClick={() => onMemberClicked("ayadiuno")}/>
                                </Grid>
                                <Grid container direction="row" justifyContent="center" className="hover">
                                    <LinkedInIcon className="linkedin-icon"/>
                                    <Typography align="center" variant="h6">
                                        Chidinma Ayadiuno (She/Her)
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography align="center" variant="body2" className="member-title-text">
                                        Compliance, Ops
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs="auto" className="mobile-member-container">
                            <Grid container direction="column" spacing={1} alignItems="center">
                                <Grid item >
                                    <img src={"team/tom-headshot.webp"} className="mobile-member-picture" alt="tom-fream" onClick={() => onMemberClicked("fream")}/>
                                </Grid>
                                <Grid container direction="row" justifyContent="center" className="hover">
                                    <LinkedInIcon className="linkedin-icon"/>
                                    <Typography align="center" variant="h6">
                                        Tom Fream (He/Him)
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography align="center" variant="body2" className="member-title-text">
                                        Software
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs="auto" className="mobile-member-container">
                            <Grid container direction="column" spacing={1} alignItems="center">
                                <Grid item >
                                    <img src={"team/vish-headshot.webp"} className="mobile-member-picture" alt="vishrut-kevadiya" onClick={() => onMemberClicked("kevadiya")}/>
                                </Grid>
                                <Grid container direction="row" justifyContent="center" className="hover">
                                    <LinkedInIcon className="linkedin-icon"/>
                                    <Typography align="center" variant="h6">
                                        Vishrut Kevadiya (He/Him)
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography align="center" variant="body2" className="member-title-text">
                                        Software (Intern)
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs="auto" className="mobile-member-container">
                            <Grid container direction="column" spacing={1} alignItems="center">
                                <Grid item >
                                    <img src={"team/amanda-headshot.webp"} className="mobile-member-picture" alt="amanda-neves" onClick={() => onMemberClicked("neves")}/>
                                </Grid>
                                <Grid container direction="row" justifyContent="center" className="hover">
                                    <LinkedInIcon className="linkedin-icon"/>
                                    <Typography align="center" variant="h6">
                                        Amanda Neves (They/Them)
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography align="center" variant="body2" className="member-title-text">
                                        AI, 3D
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs="auto" className="mobile-member-container">
                            <Grid container direction="column" spacing={1} alignItems="center">
                                <Grid item >
                                    <img src={"team/zoe-headshot.webp"} className="mobile-member-picture" alt="zoe-vogeli" onClick={() => onMemberClicked("vogeli")}/>
                                </Grid>
                                <Grid container direction="row" justifyContent="center" className="hover">
                                    <LinkedInIcon className="linkedin-icon"/>
                                    <Typography align="center" variant="h6">
                                        Zoe Vogeli (She/Her)
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography align="center" variant="body2" className="member-title-text">
                                        Commercialization
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="body2" className="mobile-blurb-text">
                        At Deep Surface AI, our mission is to make 3D planning software that works like a natural extension of your medical aesthetics expertise. We’ve built surgical best practices, data-driven AI, and elegant feature design into our morphing tools to provide you and your patients with the ideal platform for visualizing and selecting aesthetics objectives.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className="action-text">
                        <Grid container direction="column" spacing={4} alignItems="center" className="call-to-action-banner">
                            <Grid item>
                                <Typography variant="h2" align="center" className="call-to-action-text">
                                    Stay connected with our team.
                                </Typography>
                            </Grid>
                            <Grid item>
                                <LinkedInIcon className="linkedin-icon" onClick={onLinkedinClicked}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }

    return (
        <div>
            {windowSize.width > 850
                ?
                    desktopPageLayout()
                :
                    mobilePageLayout()
            }
        </div>
    );
};

export default AboutUs;
