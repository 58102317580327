import BlogPages from './blogPages'
import "./styles.css";
import { Grid, Typography } from "@material-ui/core";


const data = [
    {
      title: 'Celebrating Pride Month',
      date: 'JULY 15,2023',
      content: 
`
<html>

<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <style type="text/css">
        @import url(https://themes.googleusercontent.com/fonts/css?kit=wAPX1HepqA24RkYW1AuHYA);

        .lst-kix_jbgy5xfckrrz-0>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_jbgy5xfckrrz-8 {
            list-style-type: none
        }

        .lst-kix_jbgy5xfckrrz-6>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_jbgy5xfckrrz-4 {
            list-style-type: none
        }

        .lst-kix_jbgy5xfckrrz-5>li:before {
            content: "\\0025a0   "
        }

        ul.lst-kix_jbgy5xfckrrz-5 {
            list-style-type: none
        }

        ul.lst-kix_jbgy5xfckrrz-6 {
            list-style-type: none
        }

        ul.lst-kix_jbgy5xfckrrz-7 {
            list-style-type: none
        }

        ul.lst-kix_jbgy5xfckrrz-0 {
            list-style-type: none
        }

        .lst-kix_jbgy5xfckrrz-3>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_jbgy5xfckrrz-7>li:before {
            content: "\\0025cb   "
        }

        ul.lst-kix_jbgy5xfckrrz-1 {
            list-style-type: none
        }

        ul.lst-kix_jbgy5xfckrrz-2 {
            list-style-type: none
        }

        .lst-kix_jbgy5xfckrrz-4>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_jbgy5xfckrrz-8>li:before {
            content: "\\0025a0   "
        }

        ul.lst-kix_jbgy5xfckrrz-3 {
            list-style-type: none
        }

        .lst-kix_jbgy5xfckrrz-1>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_jbgy5xfckrrz-2>li:before {
            content: "\\0025a0   "
        }

        ol {
            margin: 0;
            padding: 0
        }

        table td,
        table th {
            padding: 0
        }

        .c0 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.5;
            orphans: 2;
            widows: 2;
            text-align: left;
            height: 11pt
        }

        .c4 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.5;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c5 {
            -webkit-text-decoration-skip: none;
            color: #1155cc;
            text-decoration: underline;
            text-decoration-skip-ink: none
        }

        .c3 {
            color: #000000;
            text-decoration: none;
            vertical-align: baseline;
            font-style: normal
        }

        .c6 {
            font-weight: 400;
            font-size: 13pt;
            line-height: 1.5;
            font-family: "Arial"
        }

        .c2 {
            font-size: 14pt;
            line-height: 1.5;
            font-family: "Calibri";
            font-weight: 400
        }

        .c7 {
            background-color: #ffffff;
            max-width: 468pt;
            padding: 72pt 72pt 72pt 72pt
        }

        .c1 {
            color: inherit;
            text-decoration: inherit
        }


        li {
            color: #000000;
            font-size: 13pt;
            line-height: 1.5;
            font-family: "Arial"
        }

        p {
            margin: 0;
            color: #000000;
            font-size: 13pt;
            line-height: 1.5;
            font-family: "Arial"
        }

    </style>
</head>

<body class="c7 doc-content">
    <p class="c0"><span class="c3 c2"></span></p>
    <p class="c4"><span class="c2">In spirit of the vibrant and empowering month of Pride, we at Deep Surface AI would
            like to share our commitment to celebrating diversity, inclusivity, and </span><span
            class="c2">equity</span><span class="c3 c2">. &#x1f3f3;&#65039;&zwj;&#x1f308;&#10024;</span></p>
    <p class="c4"><span class="c3 c2">&nbsp;</span></p>
    <p class="c4"><span class="c3 c2">We are proud to be pioneers in the field of gender-affirming facial surgery
            visualization software. Our software empowers surgeons and their transgender and gender diverse patients to
            collaborate closely, facilitating transparent and personalized discussions about desired and achievable
            outcomes. </span></p>
    <p class="c4"><span class="c3 c2">&nbsp;</span></p>
    <p class="c4"><span class="c2">A few members of Deep Surface AI recently attended the National Transgender Health
            Summit. It was inspiring, filled with smart and powerful leaders in the transgender community, and it
            highlighted the importance of &nbsp;protecting and improving transgender healthcare. </span></p>
    <p class="c4"><span class="c3 c2">&nbsp;</span></p>
    <p class="c4"><span class="c2">Transgender healthcare indisputably saves lives &nbsp;(</span><span class="c5 c2"><a
                class="c1"
                target="_blank"
                href="https://jamanetwork.com/journals/jamanetworkopen/fullarticle/2789423">Tordoff
                et al., 2022</a></span><span class="c2">; </span><span class="c2 c5"><a class="c1"
                target="_blank"
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8082431/">Almazan
                &amp; Keuroghlian, 2021</a></span><span class="c3 c2">) . Being even a small part of some
            individuals&#39; transition journey, we do not take lightly our responsibility to ensure that our software
            is accessible, user-friendly, and sensitive to diverse identities.</span></p>
    <p class="c0"><span class="c3 c2"></span></p>
    <p class="c4"><span class="c3 c2">We would like to express our heartfelt gratitude to the LGBTQ+ community for
            welcoming us and inspiring us to create software that not only transforms appearances but also uplifts
            spirits and empowers people to flourish.</span></p>
    <p class="c4"><span class="c3 c2">&nbsp;</span></p>
    <p class="c4"><span class="c2 c3">Happy Pride Month! &#x1f308;&#x1f31f;</span></p>
    <p class="c0"><span class="c3 c6"></span></p>
    <p class="c0"><span class="c3 c6"></span></p>
</body>

</html>
`, 
      link: [
        {
          text: '',
          url: '',
        },
        {
          text: '',
          url: '',
        },
      ], 
      image: '/blog/blog-img.jpg', 
      imageAlt: 'pride-blog-img'
    }
  ]


const davidKatzBlog = () => {
  return (
    <BlogPages data={data[0]}>
        <Grid item id="blog-document-paragraph" >
            <Typography variant="body2" align="left" id="blog-document-header-text">
                <div dangerouslySetInnerHTML={{ __html: data[0].content}} />
            </Typography>
          </Grid>
    </BlogPages>
  )
}

export default davidKatzBlog