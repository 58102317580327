import React from 'react'
import BlogPages from './blogPages'
import "./styles.css";
import { Grid, Typography } from "@material-ui/core";


const data = [
    {
      title: 'What is and is not AI in us and us in AI',
      date: 'NOV 3,2023',
      content: 
`
<html>

<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <style type="text/css">
        .lst-kix_o1e5yu4w0b15-4>li {
            counter-increment: lst-ctn-kix_o1e5yu4w0b15-4
        }

        ol.lst-kix_o1e5yu4w0b15-1.start {
            counter-reset: lst-ctn-kix_o1e5yu4w0b15-1 0
        }

        ol.lst-kix_o1e5yu4w0b15-4.start {
            counter-reset: lst-ctn-kix_o1e5yu4w0b15-4 0
        }

        .lst-kix_o1e5yu4w0b15-0>li {
            counter-increment: lst-ctn-kix_o1e5yu4w0b15-0
        }

        ul.lst-kix_jd17zjldf592-8 {
            list-style-type: none
        }

        ul.lst-kix_jd17zjldf592-7 {
            list-style-type: none
        }

        ol.lst-kix_o1e5yu4w0b15-2.start {
            counter-reset: lst-ctn-kix_o1e5yu4w0b15-2 0
        }

        ul.lst-kix_jd17zjldf592-6 {
            list-style-type: none
        }

        ul.lst-kix_jd17zjldf592-5 {
            list-style-type: none
        }

        ol.lst-kix_o1e5yu4w0b15-5.start {
            counter-reset: lst-ctn-kix_o1e5yu4w0b15-5 0
        }

        ul.lst-kix_jd17zjldf592-4 {
            list-style-type: none
        }

        ul.lst-kix_jd17zjldf592-3 {
            list-style-type: none
        }

        ul.lst-kix_jd17zjldf592-2 {
            list-style-type: none
        }

        ul.lst-kix_jd17zjldf592-1 {
            list-style-type: none
        }

        ul.lst-kix_jd17zjldf592-0 {
            list-style-type: none
        }

        .lst-kix_o1e5yu4w0b15-5>li {
            counter-increment: lst-ctn-kix_o1e5yu4w0b15-5
        }

        ol.lst-kix_o1e5yu4w0b15-8.start {
            counter-reset: lst-ctn-kix_o1e5yu4w0b15-8 0
        }

        .lst-kix_o1e5yu4w0b15-8>li {
            counter-increment: lst-ctn-kix_o1e5yu4w0b15-8
        }

        .lst-kix_jd17zjldf592-7>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_jd17zjldf592-8>li:before {
            content: "\\0025a0   "
        }

        ol.lst-kix_o1e5yu4w0b15-6.start {
            counter-reset: lst-ctn-kix_o1e5yu4w0b15-6 0
        }

        .lst-kix_jd17zjldf592-5>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_jd17zjldf592-6>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_o1e5yu4w0b15-7>li {
            counter-increment: lst-ctn-kix_o1e5yu4w0b15-7
        }

        .lst-kix_o1e5yu4w0b15-2>li {
            counter-increment: lst-ctn-kix_o1e5yu4w0b15-2
        }

        ol.lst-kix_o1e5yu4w0b15-0 {
            list-style-type: none
        }

        ol.lst-kix_o1e5yu4w0b15-1 {
            list-style-type: none
        }

        ol.lst-kix_o1e5yu4w0b15-2 {
            list-style-type: none
        }

        ol.lst-kix_o1e5yu4w0b15-3 {
            list-style-type: none
        }

        ol.lst-kix_o1e5yu4w0b15-4 {
            list-style-type: none
        }

        ol.lst-kix_o1e5yu4w0b15-5 {
            list-style-type: none
        }

        ol.lst-kix_o1e5yu4w0b15-6 {
            list-style-type: none
        }

        ol.lst-kix_o1e5yu4w0b15-7 {
            list-style-type: none
        }

        ol.lst-kix_o1e5yu4w0b15-8 {
            list-style-type: none
        }

        .lst-kix_o1e5yu4w0b15-3>li {
            counter-increment: lst-ctn-kix_o1e5yu4w0b15-3
        }

        .lst-kix_o1e5yu4w0b15-6>li {
            counter-increment: lst-ctn-kix_o1e5yu4w0b15-6
        }

        .lst-kix_o1e5yu4w0b15-8>li:before {
            content: "" counter(lst-ctn-kix_o1e5yu4w0b15-8, lower-roman) ". "
        }

        ol.lst-kix_o1e5yu4w0b15-7.start {
            counter-reset: lst-ctn-kix_o1e5yu4w0b15-7 0
        }

        .lst-kix_o1e5yu4w0b15-7>li:before {
            content: "" counter(lst-ctn-kix_o1e5yu4w0b15-7, lower-latin) ". "
        }

        ol.lst-kix_o1e5yu4w0b15-0.start {
            counter-reset: lst-ctn-kix_o1e5yu4w0b15-0 0
        }

        .lst-kix_o1e5yu4w0b15-6>li:before {
            content: "" counter(lst-ctn-kix_o1e5yu4w0b15-6, decimal) ". "
        }

        li.li-bullet-0:before {
            margin-left: -18pt;
            white-space: nowrap;
            display: inline-block;
            min-width: 18pt
        }

        .lst-kix_o1e5yu4w0b15-5>li:before {
            content: "" counter(lst-ctn-kix_o1e5yu4w0b15-5, lower-roman) ". "
        }

        .lst-kix_o1e5yu4w0b15-2>li:before {
            content: "" counter(lst-ctn-kix_o1e5yu4w0b15-2, lower-roman) ". "
        }

        .lst-kix_jd17zjldf592-0>li:before {
            content: "\\0025cf   "
        }

        ol.lst-kix_o1e5yu4w0b15-3.start {
            counter-reset: lst-ctn-kix_o1e5yu4w0b15-3 0
        }

        .lst-kix_o1e5yu4w0b15-0>li:before {
            content: "" counter(lst-ctn-kix_o1e5yu4w0b15-0, decimal) ". "
        }

        .lst-kix_o1e5yu4w0b15-1>li {
            counter-increment: lst-ctn-kix_o1e5yu4w0b15-1
        }

        .lst-kix_o1e5yu4w0b15-4>li:before {
            content: "" counter(lst-ctn-kix_o1e5yu4w0b15-4, lower-latin) ". "
        }

        .lst-kix_jd17zjldf592-1>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_jd17zjldf592-2>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_o1e5yu4w0b15-3>li:before {
            content: "" counter(lst-ctn-kix_o1e5yu4w0b15-3, decimal) ". "
        }

        .lst-kix_jd17zjldf592-3>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_jd17zjldf592-4>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_o1e5yu4w0b15-1>li:before {
            content: "" counter(lst-ctn-kix_o1e5yu4w0b15-1, lower-latin) ". "
        }

        ol {
            margin: 0;
            padding: 0
        }

        table td,
        table th {
            padding: 0
        }

        .c14 {
            color: #000000;
            font-weight: 400;
            line-height: 1.5;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "Arial";
            font-style: normal
        }

        .c1 {
            color: #000000;
            font-weight: 400;
            line-height: 1.5;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 13pt;
            font-family: "Arial";
            font-style: normal
        }

        .c8 {
            color: #000000;
            font-weight: 400;
            line-height: 1.5;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 13pt;
            font-family: "Arial";
            font-style: italic
        }

        .c3 {
            color: #000000;
            font-weight: 400;
            line-height: 1.5;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 12pt;
            font-family: "Arial";
            font-style: normal
        }

        .c7 {
            color: #000000;
            line-height: 1.5;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 13pt;
            font-family: "Arial";
            font-style: normal
        }

        .c0 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.5;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c4 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.5;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c16 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.5;
            orphans: 2;
            widows: 2;
            text-align: center
        }

        .c15 {
            text-decoration-skip-ink: none;
            -webkit-text-decoration-skip: none;
            color: #1155cc;
            text-decoration: underline
        }

        .c12 {
            background-color: #ffffff;
            max-width: 468pt;
            padding: 72pt 72pt 72pt 72pt
        }

        .c11 {
            width: 33%;
            height: 1px
        }

        .c13 {
            color: inherit;
            text-decoration: inherit
        }

        .c18 {
            padding: 0;
            margin: 0
        }

        .c10 {
            margin-left: 36pt;
            padding-left: 0pt
        }

        .c5 {
            height: 12pt
        }

        .c6 {
            font-size: 12pt
        }

        .c17 {
            font-size: 11pt
        }

        .c2 {
            font-weight: 700
        }

        .c9 {
            font-style: italic
        }

        li {
            color: #000000;
            font-size: 13pt;
            line-height: 1.25;
            font-family: "Arial"
        }

        p {
            margin: 0;
            color: #000000;
            font-size: 13pt;
            line-height: 1.25;
            font-family: "Arial"
        }

        .image {
            max-width: 60%;
        }

        .image2 {
            max-width: 30%;
        }

        @media(max-width: 568px){
    
            li {
                font-size: 12pt;
                
            }
    
            p {
                font-size: 12pt;
            }

            .c14 {
                font-size: 10pt;
            }
    
            .c1 {
                font-size: 12pt;
            }
    
            .c8 {
                font-size: 12pt;
            }
    
            .c3 {
                font-size: 11pt;
            }
    
            .c7 {
                font-size: 12pt;
            }
    
            .c6 {
                font-size: 11pt
            }
    
            .c17 {
                font-size: 11pt
            }
    
            #afterPhotoText {
                font-size: 10pt;
            }

            .image {
                max-width: 110%;
            }

            .image2 {
                max-width: 70%;
            }

        }

    </style>
</head>

<body class="c12 doc-content">
    <p class="c4"><span class="c7 c2">What is and is not AI in us and us in AI, Part 1</span></p>
    <p class="c4 c5"><span class="c7 c2"></span></p>
    <p class="c4"><span class="c8">This is the first part of a three- or four-part blog series about AI and us &ndash;
            you, me, Deep Surface AI &ndash; in the world. In this post, and perhaps the next one, I&rsquo;ll talk about
            some limitations and benefits to modeling ourselves on AI: what is and is not AI in us. After that,
            I&rsquo;ll look at some criticisms of LLM chatbots that are, in my view, unjustified: what is and is not us
            in AI. I&rsquo;ll conclude with a post about what is and is not AI in Deep Surface AI, our company.</span>
    </p>
    <p class="c4 c5"><span class="c1"></span></p>
    <p class="c4"><span class="c2 c7">You&rsquo;re kinda Bayesian. Don&rsquo;t overthink it. </span></p>
    <p class="c4 c5"><span class="c1"></span></p>
    <p class="c4"><span>From time to time, though less often than a </span><span class="c15"><a class="c13"
                target="_blank"
                href="https://harpers.org/archive/2015/01/come-with-us-if-you-want-to-live/">few
                years ago</a></span><span class="c1">, I still hear people say they want to &ldquo;be more
            Bayesian&rdquo; in how they approach problems. In the same vein, people sometimes stress the importance of
            &ldquo;updating&rdquo; their views based on new information, usually when criticizing a viewpoint adversary
            for failing to do so. </span></p>
    <p class="c4 c5"><span class="c1"></span></p>
    <p class="c4"><span class="c1">I&rsquo;ll admit this kind of talk impressed me at first. Back then, Bayesian
            statistics was, to me, like a mysterious gnostic gospel, degenerate to most authorities, but still whispered
            by believers beneath the hum of printers in dark, cobwebbed corners of university computer labs. Today,
            I&rsquo;m not sure what all the fuss is about. My graduate research forced me to become a competent applied
            Bayesian statistical person (&ldquo;statistician&rdquo; is too strong a word). The mysteriousness of Bayes
            has been replaced by an appreciation for its everyday sobriety. </span></p>
    <p class="c4 c5"><span class="c1"></span></p>
    <p class="c4"><span class="c1">The problem with the greater-Bayesian aspiration, as I see it, is that Bayesian analysis is a
            complete enough routine for reasoning with quantifiable information that the aspiration itself could mean
            almost anything. Let&rsquo;s consider an example. </span><span class="c1">Say you want to know how many flamingo
            rainbow unicorn rafts</span><span>&nbsp;float Calgary&rsquo;s Bow River</span><span class="c1">&nbsp;in the summertime
            &ndash; you want to estimate a </span><span class="c2">parameter</span><span class="c1">&nbsp;for the size of the
            flamingo rainbow unicorn raft population. On any given day, only some flamingo rainbow unicorn rafts float
            the Bow, so you don&rsquo;t have time to count them all. However, assuming there is something that uniquely
            identifies each raft, and that a few assumptions</span><sup><a href="#ftnt1"
                id="ftnt_ref1">[1]</a></sup><span class="c1">&nbsp;are met, you can estimate your flamingo rainbow
            unicorn raft population parameter as follows:</span></p>
    <p class="c4 c5"><span class="c1"></span></p>
    <ol class="c18 lst-kix_o1e5yu4w0b15-0 start" start="1">
        <li class="c4 c10 li-bullet-0"><span class="c1">You need an initial notion of the range of possible population sizes,
                weighted by their relative plausibility. This is your </span><span class="c2">prior</span><span
                class="c1">. If you recently saw a flotilla of 10 flamingo rainbow unicorns drift past, there&rsquo;s
                not much reason to consider a population size smaller than 10, and true population sizes larger than 10
                are more plausible. Usually there is nothing wrong with choosing a prior with no differences in relative
                plausibility (an uninformative or flat prior). Nor is there anything wrong with trying out multiple
                priors.</span></li>
        <li class="c4 c10 li-bullet-0"><span>Sample well twice, i.e., collect </span><span class="c2">data</span><span
                class="c1">. In the first sampling period, count each unique flamingo rainbow unicorn raft you observe
                on the Bow. In the second, count again, dividing your observations into newly observed rafts and rafts
                you observed in the first sampling. </span></li>
        <li class="c4 c10 li-bullet-0"><span>Plug your data into a (likelihood) </span><span
                class="c2">function</span><sup><a href="#ftnt2" id="ftnt_ref2">[2]</a></sup><span class="c1">&nbsp;to
                compute the probability of each plausible flamingo rainbow unicorn raft population size. </span></li>
        <li class="c4 c10 li-bullet-0"><span>Confront your prior with these probabilities</span><sup><a href="#ftnt3"
                    id="ftnt_ref3">[3]</a></sup><span>&nbsp;to obtain an </span><span class="c2">updated</span><span>,
                or </span><span class="c2">posterior</span><span class="c1">, notion of the plausibility of different
                population size parameter estimates. </span></li>
    </ol>
            <p class="c16"><span class="img-container">
            <img class = "image"
            alt="" src="/blog/image1.png"
            title=""></span></p>
        <p class="c16" ><span>
        </span><span class="c3 c10" id="afterPhotoText">&copy; Deep Surface AI (artist: Amanda Neves)</span></p>
        <p class="c4 c5"><span class="c1"></span></p>

    <p class="c4"><span class="c1">There are some additional features of Bayesian analysis which, while not strictly Bayesian, are
            common. Models tend to learn too much from data, so it&rsquo;s good to build guardrails against this (use
        </span><span class="c2">regularizing </span><span class="c1">priors). As models get more complex, it helps to evaluate the
            costs and benefits of adding more predictors (</span><span class="c2">model comparison</span><span class="c1">) and to
            use efficient algorithms to search the incomprehensibly massive number of possible parameter value
            combinations for the best supported combination (</span><span class="c2">sample the posterior</span><span>).
        </span></p>
    <p class="c4 c5"><span class="c1"></span></p>
    <p class="c4"><span class="c1">With all of this loosely in mind, what could it possibly mean to be more Bayesian in
            everyday life? Greater-Bayesians are people, not computers, so it can&rsquo;t be about better likelihood
            functions or posterior sampling strategies. In practice, priors don&rsquo;t tend to matter much.
            What&rsquo;s left is a mish-mash of ways people can improve their decision making. Too capricious or rigid?
            Regularize harder or softer. Stuck on one approach to a problem? Try different models. It&rsquo;s all so
            very context-specific to the individual and/or situation. Most importantly, the real improvements come from
            the hard work: understanding your problem better; collecting better data and lots of it. However, I
            don&rsquo;t think the hard work is what the greater-Bayesians are getting at, and besides, the benefit of
            the hard work isn&rsquo;t unique to Bayesians.</span></p>
    <p class="c4 c5"><span class="c1"></span></p>
    <p class="c4"><span class="c1">Nevertheless, some of the ways our brains work are, or feel, spookily Bayesian. The other day I
            parked my car by Edworthy Park, near downtown Calgary and looked up just as the hindquarters of some animal
            passed out of the frame of my rearview mirror. My thoughts, more or less, went </span><span
            class="c9 c1">&ldquo;Dog&hellip; Big!&hellip; TOO BIG!... Horse?... No!,&rdquo; </span><span class="c1">and I turned
            around to see a </span><span>moose</span><span class="c1">. I have never seen a moose in the city proper.</span><sup><a
                href="#ftnt4" id="ftnt_ref4">[4]</a></sup><span class="c1">&nbsp;On the other hand, Edworthy Park is teeming with
            dogs. My priors were strong enough that I misclassified</span><sup><a href="#ftnt5"
                id="ftnt_ref5">[5]</a></sup><span>&nbsp;a four-legged animal of 30x dog mass as </span><span
            class="c9">dog</span><span class="c1">, though not so calcified as to prevent me from updating to moose once I saw the
            animal in full. By the way, there are occasionally deer in Edworthy. So why not </span><span
            class="c9">deer</span><span class="c1">? I have no idea. I can make up a story for you, but I don&rsquo;t
            have access to my own code.</span></p>
    <p class="c4 c5"><span class="c1"></span></p>
            <div class="c16">
                <span >
                    <img class = "image2" alt="" src="/blog/byDavidKatz.jpg" >
                </span>
            </div>  
        <p class="c16"><span class="c8" id="afterPhotoText">&nbsp; 
            &nbsp; &copy; Deep Surface AI (photo: David Katz)</span></p>

    <p class="c4 c5"><span class="c1"></span></p>
    <p class="c4"><span class="c1">And that&rsquo;s the point, really. The ways in which we are Bayesian lie deep in our cognitive
            web and are not easily manipulated through intention. You can adopt a Bayesian self-model of your
            problem-solving mind, but it&rsquo;s more likely to affect how you </span><span class="c9">think about
        </span><span class="c1">your thinking than your thinking itself. The good news, on the other hand, is that you don&rsquo;t
            need to aspire to be Bayesian. </span><span>You just kind of are. </span></p>
    <p class="c4 c5"><span class="c1"></span></p>
    <p class="c4"><span class="c2">Next time:</span><span class="c1">&nbsp;Either more on humans mimicking algorithms or something
            on algorithms mimicking humans. Please come back and find out. </span></p>
    <hr class="c11">
    <div>
        <p class="c0"><a href="#ftnt_ref1" id="ftnt1">[1]</a><span class="c3">&nbsp;/ For example, during the study
                period, no flamingo rainbow unicorn rafts sink, come or go from the region, or mate to produce flamingo
                rainbow unicorn offspring.</span></p>
    </div>
    <div>
        <p class="c0"><a href="#ftnt_ref2" id="ftnt2">[2]</a><span class="c6">&nbsp;/</span><span
                class="c17">&nbsp;</span><span class="c6">I</span><span class="c6">n this case, a hypergeometric
                distribution</span><span class="c6">.</span></p>
    </div>
    <div>
        <p class="c0"><a href="#ftnt_ref3" id="ftnt3">[3]</a><span class="c3">&nbsp;/ More mechanically, multiplying
                prior times likelihood over the range of population sizes.</span></p>
    </div>
    <div>
        <p class="c0"><a href="#ftnt_ref4" id="ftnt4">[4]</a><span class="c6">&nbsp;/ Though one did try to </span><span
                class="c6 c15"><a class="c13"
                    target="_blank"
                    href="https://globalnews.ca/news/3785095/moose-on-the-loose-captured-on-camera-at-calgary-transit-station/">sneak
                    a ride on the LRT</a></span><span class="c3">&nbsp;a few years back.</span></p>
    </div>
    <div>
        <p class="c0"><a href="#ftnt_ref5" id="ftnt5">[5]</a><span class="c6">&nbsp;/ </span><span
                class="c6 c9">Hallucinated?</span><span class="c3">&nbsp;That&rsquo;s a question for the next blog
                post</span></p>
    </div>
</body>

</html>
`, 
      link: [
        {
          text: '',
          url: '',
        },
        {
          text: '',
          url: '',
        },
      ], 
      image: 'team/david-k-headshot.png', 
      imageAlt: 'david-blog-img'
    }
  ]


const davidKatzBlog = () => {
  return (
    <BlogPages data={data[0]}>
        <Grid item id="blog-document-paragraph" >
            <Typography variant="body2" align="left" id="blog-document-header-text">
                <div dangerouslySetInnerHTML={{ __html: data[0].content}} />
            </Typography>
          </Grid>
    </BlogPages>
  )
}

export default davidKatzBlog
