import { Button, Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import "./styles.css";
import useWindowSize from "../../hooks/useWindowSize";
import Carousel from "../../components/Carousel";

const Home = () => {
  const history = useHistory();
  const windowSize = useWindowSize();

  const onSurgeonClicked = () => {
    history.push("/surgeon-info");
  };

  const onPatientClicked = () => {
    history.push("/patient-info");
  };

  const onAboutUsClicked = () => {
    history.push("/about-us");
  };

  const onContactClicked = () => {
    history.push("/contact-us");
  };

  const desktopLayout = () => {
    return (
      <div>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="header-grid"
        >
          <Grid item xs={6}>
            <Grid
              container
              direction="column"
              spacing={2}
              justifyContent="center"
            >
              <Grid item xs="auto">
                {windowSize.width > 1200 ? (
                  <Typography
                    align="center"
                    variant="h1"
                    className="banner-title-text"
                  >
                    The only aesthetically intelligent morphing software.
                  </Typography>
                ) : (
                  <Typography
                    align="center"
                    variant="h2"
                    className="banner-title-text"
                  >
                    The only aesthetically intelligent morphing software.
                  </Typography>
                )}
                {/* <Typography align="center" variant="h1" className="banner-title-text">
                                    The only biologically intelligent morphing software. 
                                </Typography> */}
                <hr className="banner-divider" />
              </Grid>
              <Grid item xs="auto">
                <Typography
                  align="center"
                  variant="h4"
                  className="banner-subtitle-text"
                >
                  The perfect companion to your aesthetic and surgical
                  expertise.
                </Typography>
              </Grid>
              <Grid item xs={2} />
              <Grid
                container
                direction="row"
                justifyContent="center"
                spacing={4}
                className="surgeon-patient-button-container"
              >
                <Grid item xs="auto">
                  <Button variant="outlined" onClick={onSurgeonClicked}>
                    Professionals
                  </Button>
                </Grid>
                <Grid item xs="auto">
                  <Button variant="outlined" onClick={onPatientClicked}>
                    Patients
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} className="banner-image-container" />
        </Grid>
        <div className="mission-and-what-we-do-container">
          <div className="applications-title-text-div">
            <div className="applications-divider-border" />
            <span className="applications-divider-content">
              <Typography
                align="center"
                variant="h2"
                className="what-we-do-title-text"
              >
                WHAT WE DO
              </Typography>
            </span>
            <div className="applications-divider-border" />
          </div>

          <div>
            <Typography
              align="center"
              variant="h3"
              style={{ fontWeight: 500 }}
              className="mission-statement-text"
            >
              “Our 3D simulations are smart - so deciding can be simple.”
            </Typography>
            <hr className="mission-statement-divider" />
          </div>
        </div>

        <Grid
          container
          direction="row"
          justifyContent="center"
          className="about-us-grid"
        >
          <Grid item xs={6} className="about-us-image-container">
            <img
              src={"home/home-2.jpg"}
              alt="second-home"
              className="second-photo"
            />
          </Grid>
          <Grid item xs={6}>
            <Grid
              container
              direction="column"
              spacing={5}
              justifyContent="center"
            >
              <Grid item xs="auto">
                <Typography
                  align="center"
                  variant="h3"
                  className="about-us-title-text"
                >
                  Improving Aesthetic Medicine for Patients and Providers
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <Typography
                  align="left"
                  variant="body2"
                  className="about-us-body-text"
                >
                  Build patient confidence, streamline preoperative
                  consultations, and improve aesthetic outcomes. Elevate your
                  medical practice with realistic 3D morphs customized to each
                  patient’s unique face. Rooted in facial data, evidence-based
                  models, and surgical best practices, our software empowers you
                  to create 3D visual representations that match your surgical
                  vision. Finally, a compelling 3D solution for your practice.
                </Typography>
              </Grid>
              <Grid item xs={2} />
              <Grid
                container
                direction="row"
                justifyContent="center"
                className="about-us-button-container"
              >
                <Grid item xs="auto">
                  <Button variant="outlined" onClick={onAboutUsClicked}>
                    About Us
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div className="applications-title-text-div">
          <div className="applications-divider-border" />
          <span className="applications-divider-content">
            <Typography
              align="center"
              variant="h2"
              className="applications-title-text"
            >
              APPLICATIONS
            </Typography>
          </span>
          <div className="applications-divider-border" />
        </div>

        <Grid container direction="row" className="applications-title-grid">
          <Grid item xs={4} className="div-right">
            <Typography
              align="center"
              variant="h4"
              className="applications-product-name-text"
            >
              FACIAL FEMINIZATION
            </Typography>
          </Grid>
          <Grid item xs={4} className="div-both">
            <Typography
              align="center"
              variant="h4"
              className="applications-product-name-text"
            >
              FACIAL PLASTIC SURGERY
            </Typography>
          </Grid>
          <Grid item xs={4} className="div-left">
            <Typography
              align="center"
              variant="h4"
              className="applications-product-name-text"
            >
              RHINOPLASTY
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={4} className="div-right">
            <Typography
              align="left"
              variant="body2"
              className="applications-product-description-text"
            >
              For many transgender and gender-diverse patients, facial
              feminization surgery is a significant step in aligning physical
              appearance with gender identity. Our visualization software uses
              biological data to realistically feminize the 3D image of a
              patient's face.
            </Typography>
          </Grid>
          <Grid item xs={4} className="div-both">
            <Typography
              align="left"
              variant="body2"
              className="applications-product-description-text"
            >
              Simulate the most popular cosmetic facial plastic surgery
              procedures including brow lift, chin augmentation and implant,
              cheek augmentation, lip augmentation, rhinoplasty, and submental
              liposuction.
            </Typography>
          </Grid>
          <Grid item xs={4} className="div-left">
            <Typography
              align="left"
              variant="body2"
              className="applications-product-description-text"
            >
              Rhinoplasty is the most common facial cosmetic surgery procedure.
              Our data-driven proposals help surgeons and patients identify the
              options that best suit the patient's face.
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" className="carousel-container">
          <Grid item xs={4} className="product-image-container">
            <Carousel folder="facial-feminization" />
          </Grid>
          <Grid item xs={4} className="product-image-container">
            <Carousel folder="facial-plastic-surgery" />
          </Grid>
          <Grid item xs={4} className="product-image-container">
            <Carousel folder="rhinoplasty" />
          </Grid>
        </Grid>

        <Grid container direction="row" style={{ marginTop: "10vw" }}>
          <Grid item xs={2}>
            <img
              src={
                "home/background-dots/desktop/small-left-orange-dots-background-transparent.png"
              }
              alt="orange-background-dots"
              className="orange-background-dots"
            />
          </Grid>
          <Grid item xs={8}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              className="question-to-provoke-container"
            >
              <Grid item xs="auto">
                <Typography
                  align="center"
                  variant="h2"
                  className="question-to-provoke-text"
                >
                  Ready to upgrade your practice?
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <Button variant="outlined" onClick={onContactClicked}>
                  Request a Demo
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} className="bottom-green-dots-container">
            <img
              src={
                "home/background-dots/desktop/small-right-green-dots-background-transparent.png"
              }
              alt="green-background-dots"
              className="green-background-dots"
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  const mobileLayout = () => {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className="mobile-container"
      >
        <Grid item xs="auto" className="mobile-banner-image-container">
          <img
          style={{marginTop: '4rem'}}
            src={"home/Website_Teisha2.png"}
            alt="first-home"
            className="first-photo"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            align="center"
            variant="h2"
            className="mobile-banner-title-text"
          >
            The only aesthetically intelligent morphing software
          </Typography>
          <hr className="mobile-banner-divider" />
        </Grid>
        <Grid item xs={12}>
          <Typography
            align="center"
            variant="h4"
            className="mobile-banner-subtitle-text"
          >
            The perfect partner to your aesthetic and surgical expertise
          </Typography>
        </Grid>
        <Grid item xs={2} />
        <Grid
          container
          direction="row"
          justifyContent="center"
          spacing={6}
          className="surgeon-patient-button-container"
        >
          <Grid item xs="auto">
            <Button variant="outlined" onClick={onSurgeonClicked}>
              Professionals
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button variant="outlined" onClick={onPatientClicked}>
              Patients
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <img
            src={
              "home/background-dots/mobile/blue-and-green-banner-dots-transparent.png"
            }
            alt="blue-green-dots-banner"
            className="blue-green-dots-banner"
          />
        </Grid>

        <div className="mobile-applications-title-text-div">
          <div className="mobile-applications-divider-border" />
          <span className="mobile-applications-divider-content">
            <Typography
              align="center"
              variant="h2"
              className="mobile-applications-title-text"
            >
              WHAT WE DO
            </Typography>
          </span>
          <div className="mobile-applications-divider-border" />
        </div>

        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className="mobile-about-us-grid"
        >
          <Grid item xs="auto">
            <Typography
              align="center"
              variant="h4"
              className="mobile-mission-statement-text"
            >
              “Our 3D simulations are smart - so deciding can be simple.”
            </Typography>
            <hr className="mobile-banner-divider" />
          </Grid>
          <Grid item xs="auto" className="mobile-about-us-image-container">
            <img
              src={"home/home-2.jpg"}
              alt="second-home"
              className="second-photo-mobile"
            />
          </Grid>
          <Grid item xs="auto">
            <Typography
              align="center"
              variant="h3"
              className="mobile-about-us-title-text"
            >
              Improving Aesthetic Medicine for Patients and Providers
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <Typography
              align="left"
              variant="body2"
              className="mobile-about-us-body-text"
            >
              Streamline preoperative consultations, improve aesthetic outcomes,
              and relieve patient anxiety. Elevate your medical practice with
              realistic 3D morphs suited to each patient's unique face. Rooted
              in facial data, evidence-based models, and surgical best
              practices, our software empowers you to create 3D morphs that
              match your surgical vision. Finally, a compelling 3D solution for
              your practice.
            </Typography>
          </Grid>
          <Grid container direction="row" justifyContent="center">
            <Grid item xs="auto">
              <Button variant="outlined" onClick={onAboutUsClicked}>
                About Us
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <img
            src={
              "home/background-dots/mobile/orange-and-purple-banner-dots-transparent.png"
            }
            alt="orange-purple-banner-dots"
            className="blue-green-dots-banner"
          />
        </Grid>

        <div className="mobile-applications-title-text-div">
          <div className="mobile-applications-divider-border" />
          <span className="mobile-applications-divider-content">
            <Typography
              align="center"
              variant="h2"
              className="mobile-applications-title-text"
            >
              APPLICATIONS
            </Typography>
          </span>
          <div className="mobile-applications-divider-border" />
        </div>

        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className="mobile-container"
        >
          <Grid item xs={"auto"}>
            <Typography
              align="center"
              variant="h5"
              className="mobile-applications-product-name-text"
            >
              FACIAL FEMINIZATION
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <Typography
              align="left"
              variant="body2"
              className="mobile-applications-product-description-text"
            >
              Facial feminization surgery describes a suite of facial procedures
              that help transgender women and gender-diverse folk align their
              physical appearance with their gender identity. Backed by
              biological data, this software application can realistically
              feminize a 3D image of a patient's face by reshaping key facial
              characteristics to best suit the patient’s goals.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className="mobile-product-image-container">
          <Carousel folder="facial-feminization" />
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className="mobile-container"
        >
          <Grid item xs="auto">
            <Typography
              align="center"
              variant="h5"
              className="mobile-applications-product-name-text"
            >
              FACIAL PLASTIC SURGERY
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <Typography
              align="left"
              variant="body2"
              className="mobile-applications-product-description-text"
            >
              Simulate the most popular cosmetic facial plastic surgery
              procedures including brow lift, chin augmentation and implant,
              cheek augmentation, lip augmentation, rhinoplasty, and submental
              liposuction.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className="mobile-product-image-container">
          <Carousel folder="facial-plastic-surgery" />
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className="mobile-container"
        >
          <Grid item xs="auto">
            <Typography
              align="center"
              variant="h5"
              className="mobile-applications-product-name-text"
            >
              RHINOPLASTY
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <Typography
              align="left"
              variant="body2"
              className="mobile-applications-product-description-text"
            >
              Rhinoplasty is a surgical procedure that changes the appearance of
              the nose. This software application uses biological data to
              simulate the nose that is best suited to the patient's face, and
              to allow for 3D visualization of the nose from all angles.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className="mobile-product-image-container">
          <Carousel folder="rhinoplasty" />
        </Grid>

        <Grid item>
          <img
            src={"home/background-dots/mobile/orange-left-dots-transparent.png"}
            alt="orange-left-dots"
            className="mobile-orange-left-dots"
          />
        </Grid>

        <Grid item xs="auto">
          <Typography
            align="center"
            variant="h2"
            className="mobile-question-to-provoke-text"
          >
            Ready to upgrade your practice?
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <Button variant="outlined" onClick={onContactClicked}>
            Request a Demo
          </Button>
        </Grid>
        <Grid item>
          <img
            src={"home/background-dots/mobile/green-right-dots-transparent.png"}
            alt="green-left-dots"
            className="mobile-green-right-dots"
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <div>{windowSize.width > 1000 ? desktopLayout() : mobileLayout()}</div>
  );
};

export default Home;
