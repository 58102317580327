import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { useState } from "react";
import ContactFormFields from "./types";
import { Alert } from "@mui/material";
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";
import useWindowSize from "../../hooks/useWindowSize";
import "./styles.css";

const defaultFormValue: ContactFormFields = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  questions: "",
  captchaValue: null,
}

const TEMPLATE_ID = "template_x2hbk55";
const SERVICE_ID = "service_vzlsu4v";
const PUBLIC_KEY = "zt7g_Awx7IQLgrB6d";
const SITE_KEY = "6LeJePUfAAAAAAURyxB_ngvAvZlHW0dqmy3uA2m3";

const ContactForm = () => {
  const [formValues, setFormValues] = useState(defaultFormValue);
  const [emailError, setEmailError ] = useState("");
  const [phoneError, setPhoneError ] = useState("");
  const [captchaError, setCaptchaError] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const windowSize = useWindowSize();

  const checkEmail = (mailString: String): boolean => {
    // Check if email matches normal format
    // eslint-disable-next-line
      if(mailString.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) == null) {
        // If not set error
        setEmailError("Incorrect email format");
        return false;
      }
      else {
        setEmailError("");
        return true;
      }
  }

  const checkPhone = (phoneString: String) => {
    // Check if phone matches normal phone number format
    // eslint-disable-next-line
      if(phoneString.toLowerCase().match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im) == null) {
        // If not set error
        setPhoneError("Incorrect phone number format");
        return false;
      }
      else {
        setPhoneError("");
        return true;
      }
  }

  const checkCaptcha = () => {
    if (formValues.captchaValue === null) {
      setCaptchaError("ReCAPTCHA required")
      return false;
    }
    else {
      setCaptchaError("");
      return true;
    }
  }

  const sendEmail = async () => {
    var data = {
      first_name: formValues.firstName,
      last_name: formValues.lastName,
      email: formValues.email,
      phone: formValues.phone,
      message: formValues.questions,
      'g-recaptcha-response': formValues.captchaValue
    };

    emailjs.send(
      SERVICE_ID,
      TEMPLATE_ID,
      data,
      PUBLIC_KEY
    ).then(() => {
      setSuccessAlert(true);
      setAlertMessage("Email sent successfully");
      setFormValues(defaultFormValue);
      console.log(formValues);
    })
    .catch(() => {
      setErrorAlert(true);
      setAlertMessage("Email not sent successfully");
    })
  }
  
  const onSubmitClicked = () => {
    // Check phone and email
    if (!checkPhone(formValues.phone) || !checkEmail(formValues.email) || !checkCaptcha()) {
      return;
    }

    // If they are valid, send email
    sendEmail();
  }

  const desktopFormView = () => {
    return(
      <Grid container direction="column" justifyContent="center">
        <Grid item>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={5}>
              <Grid container direction="column" spacing={1 }>
                <Grid item>
                  <Typography variant="body2">
                    First Name
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    className="form-item"
                    size="small"
                    required
                    variant="outlined"
                    defaultValue={formValues.firstName}
                    onChange={(input) => {setFormValues({...formValues, firstName: input.target.value})}}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    Last Name
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    className="form-item"
                    size="small"
                    variant="outlined"
                    required
                    defaultValue={formValues.lastName}
                    onChange={(input) => {setFormValues({...formValues, lastName: input.target.value})}}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    Phone Number
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    error={true}
                    helperText={phoneError}
                    className="form-item"
                    size="small"
                    variant="outlined"
                    required
                    defaultValue={formValues.phone}
                    onChange={(input) => {setFormValues({...formValues, phone: input.target.value})}}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    Email
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    error={true}
                    helperText={emailError}
                    className="form-item"
                    size="small"
                    variant="outlined"
                    required
                    defaultValue={formValues.email}
                    onChange={(input) => {setFormValues({...formValues, email: input.target.value})}}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs="auto">
              <Grid container direction="column" >
                <Grid item>
                  <Typography variant="body2" className="message-text">
                    Message
                  </Typography>
                </Grid>
                <TextField
                    variant="outlined"
                    className="comment-input"
                    required
                    defaultValue={formValues.questions}
                    multiline
                    minRows={10}      
                    onChange={(input) => {setFormValues({...formValues, questions: input.target.value})}}
                  />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="captcha-container">
          <ReCAPTCHA
            sitekey={SITE_KEY}
            onChange={(token: String | null) => { setFormValues({...formValues, captchaValue: token})}}
          />
        </Grid>
        <Grid item className="captcha-error-text-container">
          <Typography variant="body2" className="captcha-error-text">
            {captchaError}
          </Typography>
        </Grid>
        <Grid item className="submit-button-container">
          <Button variant="outlined" onClick={onSubmitClicked}>
            Submit
          </Button>
        </Grid>
      </Grid>
    )
  }

  const mobileFormView = () => {
    return (
      <div>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="body2">
            First Name
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            className="mobile-form-item"
            size="small"
            required
            variant="outlined"
            defaultValue={formValues.firstName}
            onChange={(input) => {setFormValues({...formValues, firstName: input.target.value})}}
          />
        </Grid>
        <Grid item>
          <Typography variant="body2">
            Last Name
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            className="mobile-form-item"
            size="small"
            variant="outlined"
            required
            defaultValue={formValues.lastName}
            onChange={(input) => {setFormValues({...formValues, lastName: input.target.value})}}
          />
        </Grid>
        <Grid item>
          <Typography variant="body2">
            Phone Number
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            error={true}
            helperText={phoneError}
            className="mobile-form-item"
            size="small"
            variant="outlined"
            required
            defaultValue={formValues.phone}
            onChange={(input) => {setFormValues({...formValues, phone: input.target.value})}}
          />
        </Grid>
        <Grid item>
          <Typography variant="body2">
            Email
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            error={true}
            helperText={emailError}
            className="mobile-form-item"
            size="small"
            variant="outlined"
            required
            defaultValue={formValues.email}
            onChange={(input) => {setFormValues({...formValues, email: input.target.value})}}
          />
        </Grid>
        <Grid item>
          <Typography variant="body2" className="message-text">
            Message
          </Typography>
        </Grid>
        <Grid item>
          <TextField
              variant="outlined"
              className="mobile-form-item"
              required
              defaultValue={formValues.questions}
              multiline
              minRows={10}      
              onChange={(input) => {setFormValues({...formValues, questions: input.target.value})}}
            />
        </Grid>
        <Grid item>
          <ReCAPTCHA
            sitekey={SITE_KEY}
            onChange={(token: String | null) => { setFormValues({...formValues, captchaValue: token})}}
          />
        </Grid>
        <Grid item>
          <Typography variant="body2" className="mobile-captcha-error-text">
            {captchaError}
          </Typography>
        </Grid>
        <Grid item className="mobile-submit-button-container">
          <Button variant="outlined" onClick={onSubmitClicked}>
            Submit
          </Button>
        </Grid>
      </Grid>
      </div>
    )
  }

  return (
    <div>
      {windowSize.width > 640
          ?
              desktopFormView()
          :
              mobileFormView()
      }
      {errorAlert ? 
        <Alert severity="error" onClose={() => { setErrorAlert(false) }} className="email-alert">
          {alertMessage}
        </Alert>
        :
        <div/>
      }
      { successAlert ? 
        <Alert severity="success" onClose={() => { setSuccessAlert(false) }} className="email-alert">
          {alertMessage}
        </Alert>
        :
        <div/>
      }
    </div>
  )
}

export default ContactForm;