import AboutUs from "../pages/AboutUs";
import ContactUs from "../pages/ContactUs";
import Home from "../pages/Home";
import PatientInfo from "../pages/PatientInfo";
import SurgeonInfo from "../pages/SurgeonInfo";
import Blog from "../pages/Blog";
import BlogPage from "../pages/Blogs/PrideMonth";
import TimBlogPage from "../pages/Blogs/TimBlog";
import PrivacyPolicy from "../pages/Policy/privacyPolicy";
import DavidKatz from "../pages/Blogs/davidKatzBlog";

const routes = [
    {
      key: "Policy",
      path: "/privacy-policy",
      exact: true,
      component: PrivacyPolicy,
    },
    {
      key: "PrideBlogPage",
      path: "/pride-blog-page",
      exact: true,
      component: BlogPage,
    },
    {
      key: "TimBlogPage",
      path: "/tim-cruz-blog-page",
      exact: true,
      component: TimBlogPage,
    },
    {
      key: "DavidKatzBlogPage",
      path: "/david-katz-blog-page",
      exact: true,
      component: DavidKatz,
    },
    {
      key: "Blog",
      path: "/blog",
      exact: true,
      component: Blog,
    },
    {
      key: "Home",
      path: "/",
      exact: true,
      component: Home,
    },
    {
      key: "SurgeonInfo",
      path: "/surgeon-info",
      exact: true,
      component: SurgeonInfo,
    },
    {
      key: "PatientInfo",
      path: "/patient-info",
      exact: true,
      component: PatientInfo,
    },
    {
      key: "AboutUs",
      path: "/about-us",
      exact: true,
      component: AboutUs,
    },
    {
      key: "ContactUs",
      path: "/contact-us",
      exact: true,
      component: ContactUs,
    },
  ];

export default routes;