import { Grid, Typography } from "@material-ui/core";
import useWindowSize from "../../hooks/useWindowSize";
import "./styles.css";

const PatientInfo = () => {
    const windowSize = useWindowSize();

    const desktopPageLayout = () => {
        return(
            <div>
                <Grid container direction="column" className="patient-title-and-banner-container" >
                    <Grid xs={12} item className="patient-title-text-container">
                        <Typography variant="h1" align="center" className="patient-header-text">
                            See your authentic self with <br /> Deep Surface AI software.
                        </Typography>
                    </Grid>
                    {/* <Grid item xs="auto">
                        <Grid container direction="row" justifyContent="center" >
                            <Grid item xs={6} className="banner-picture"/>
                        </Grid>
                    </Grid> */}
                </Grid>
                <div className="patient-applications-title-text-div">
                    <div className="patient-applications-divider-border"/>
                        <span className="patient-applications-divider-content">
                            <Typography align="center" variant="h4" className="patient-applications-title-text">
                                Be confident that your aesthetic goals and desires are understood. 
                            </Typography>
                        </span>
                    <div className="patient-applications-divider-border"/>
                </div>

                <Grid container direction="row" justifyContent="center" className="reasons-container">
                    <Grid item xs={4} className="patient-div-right">
                        <Grid container direction="column">
                            <Grid item>
                                <Typography align="center" variant="h4">
                                    PERSONALIZED
                                </Typography>
                            </Grid>
                           <Grid item>
                                <Typography variant="body2" className="patient-reason-text">
                                    Our software recommends aesthetic possibilities that fit your unique facial characteristics and personal identity. View and modify these proposals to see different surgical possibilities on your own face.
                                </Typography>
                           </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} className="patient-div-right">
                        <Grid container direction="column">
                            <Grid item>
                                <Typography align="center" variant="h4">
                                    CONFIDENCE
                                </Typography>
                            </Grid>
                           <Grid item>
                                <Typography variant="body2" className="patient-reason-text">
                                    Personalized surgical planning with a 3D image of your desired results is the best way to be confident in your surgical goals. Know which procedures are right for you by seeing them digitally before committing to surgery.
                                </Typography>
                           </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container direction="column">
                            <Grid item>
                                <Typography align="center" variant="h4">
                                    COMMUNICATION
                                </Typography>
                            </Grid>
                           <Grid item>
                                <Typography variant="body2" className="patient-reason-text">
                                    If a 2D picture is worth a thousand words, then 3D is worth a million. Your personalized 3D morph is the easiest way to communicate with your surgeon about what you want. Know with certainty you share the same vision. 
                                </Typography>
                           </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid container direction="column" className="patient-call-to-action-container" justifyContent="center">
                    <Grid xs={10} item className="title-text-container">
                        <Typography variant="h2" align="center" className="patient-call-to-action-text">
                            Ask your surgeon about Deep Surface AI.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" className="brochure-button">
                            GET A BROCHURE
                        </Button>
                    </Grid>
                </Grid> */}
            </div>
        )
    }

    const mobilePageLayout = () => {
        return (
            <div>
                <Grid container direction="column" className="mobile-patient-title-and-banner-container">
                    <Grid item className="patient-mobile-title-text-container">
                        <Typography variant="h3" align="center" className="mobile-patient-header-text">
                            {/* Discover surgical possibilities uniquely suited for your face, and communicate your aesthetic desires with ease.  */}
                            See your authentic self with Deep Surface AI software.
                        </Typography>
                    </Grid>
                    {/* <Grid item xs="auto">
                        <Grid container direction="row" justifyContent="center" spacing={2}>
                            <Grid item xs={10} className="mobile-banner-picture"/>
                        </Grid>
                    </Grid> */}
                </Grid>
                <div className="patient-applications-title-text-div">
                    <div className="patient-applications-divider-border"/>
                        <span className="patient-applications-divider-content">
                            <Typography align="center" variant="h4" className="patient-applications-title-text">
                                Be confident that your aesthetic goals and desires are understood. 
                            </Typography>
                        </span>
                    <div className="patient-applications-divider-border"/>
                </div>
                <Grid container direction="column" justifyContent="center" spacing={2} className="mobile-reasons-container">
                    <Grid item xs={10}>
                        <Typography variant="h5" className="patient-mobile-reason-title-container" align="center">
                            PERSONALIZED
                        </Typography>
                    </Grid>
                    <Grid item xs={10} className="patient-mobile-reason-container">
                        <Typography variant="body2" className="patient-reason-text">
                            Our software recommends aesthetic possibilities that fit your unique facial characteristics and personal identity. View and modify these proposals to see different surgical possibilities on your own face.
                        </Typography>
                    </Grid>

                    <Grid item xs={10}>
                        <Typography variant="h5" className="patient-mobile-reason-title-container" align="center">
                            CONFIDENCE
                        </Typography>
                    </Grid>
                    <Grid item xs={10} className="patient-mobile-reason-container">
                        <Typography variant="body2" className="patient-reason-text">
                            Personalized surgical planning with a 3D image of your desired results is the best way to be confident in your surgical goals. Know which procedures are right for you by seeing them digitally before committing to surgery.
                        </Typography>
                    </Grid>

                    <Grid item xs={10}>
                        <Typography variant="h5" className="patient-mobile-reason-title-container" align="center">
                            COMMUNICATION
                        </Typography>
                    </Grid>
                    <Grid item xs={10} className="patient-mobile-reason-container">
                        <Typography variant="body2" className="patient-reason-text">
                            If a 2D picture is worth a thousand words, then 3D is worth a million. Your personalized 3D morph is the easiest way to communicate with your surgeon about what you want. Know with certainty you share the same vision. 
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        )
    }
    return (
        <div>
            {(windowSize.width > 1050)
                ?
                    desktopPageLayout()
                :
                    mobilePageLayout()
            }
        </div>
    );
};

export default PatientInfo;