import * as React from "react";
import { IconButton, StepIcon } from "@material-ui/core";
import { Label } from "@mui/icons-material";
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import SwipeableViews from "react-swipeable-views";
import useWindowSize from "../../hooks/useWindowSize";
import "./styles.css";

interface CarouselProps {
    folder: string,
}

const Carousel = (props: CarouselProps) => {
    const windowSize = useWindowSize();
    const [activeStep, setActiveStep] = React.useState(0);

    var images;
    if (props.folder === "rhinoplasty") {
        images = [
            {
                label: "before-morph-side",
                path: "assets/morphs/" + props.folder + "/vicky-before.png"
            },
            {
                label: "after-morph-side",
                path: "assets/morphs/" + props.folder + "/vicky-after.png"
            }
        ];
    } else if (props.folder === "facial-plastic-surgery") {
        images = [
            {
                label: "before-morph-full",
                path: "assets/morphs/" + props.folder + "/justice-before.png"
            },
            {
                label: "before-morph",
                path: "assets/morphs/" + props.folder + "/justice-after.png"
            },
        ];
    } else { // ffs
        images = [
            {
                label: "before-morph-front",
                path: "assets/morphs/" + props.folder + "/kat-before.png"
            },
            {
                label: "after-morph-front",
                path: "assets/morphs/" + props.folder + "/kat-after.png"
            }
        ];
    }
    
    /* Swipeable functions */
    const handleStepChange = (step: number) => {
        setActiveStep(step);
    }

    /* Stepper functions */
    const handleStep = (step: number) => () => {
        setActiveStep(step);
    }
    const activeStepIcon = () => {
        return (
            <StepIcon icon={<CircleIcon className="circle-icon"/>} />
        );
    }
    const inactiveStepIcon = () => {
        return (
            <StepIcon icon={<CircleOutlinedIcon className="circle-icon"/>} />
        );
    }

    return (
        <div>
            <SwipeableViews index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
                {
                    images.map(
                        (step, index) => (
                            <div key={step.label} className="container-div">
                                {Math.abs(activeStep - index) <= 2
                                    ?
                                    (
                                        <div>
                                            {(windowSize.width > 1000)                                            
                                                ?
                                                <div className="img-div">
                                                    <img src={step.path} alt={step.label} className="img"/>
                                                </div>
                                                :
                                                <div className="mobile-img-div">
                                                    <img src={step.path} alt={step.label} className="img"/>
                                                </div>
                                            }
                                        </div>
                                    ) 
                                    : 
                                    null
                                }
                            </div>
                        )
                    )
                }
            </SwipeableViews>

            <div className="indicators">
                {                    
                    images.map(
                        (step, index) => (
                            <IconButton key={index} onClick={handleStep(index)} disableRipple disableFocusRipple color="inherit"
                                children={<Label component={index === activeStep ? activeStepIcon : inactiveStepIcon}/>}/>
                        )
                    )
                }
            </div>
        </div>
    );
}

export default Carousel;