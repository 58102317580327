import "./styles.css";
import { AppBar, Drawer, Grid, IconButton, Typography, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useWindowSize } from "../../hooks/useWindowSize";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import { KeyboardDoubleArrowDown } from "@mui/icons-material";

const Header = () => {
    const history = useHistory();
    const windowSize = useWindowSize();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const onBlogClicked = () => {
        history.push("/blog");
    }

    const onSurgeonClicked = () => {
        history.push("/surgeon-info");
    }

    const onPatientClicked = () => {
        history.push("/patient-info");
    }

    const onAboutUsClicked = () => {
        history.push("/about-us");
    }

    const onContactUsClicked = () => {
        history.push("/contact-us");
    }

    const onLogInClicked = () => {
        window.open("https://www.app.deepsurfaceai.com/", '_blank')
    }

    const onLogoClicked = () => {
        history.push("/");
    }

    const desktopMenu = () => {
        return (
            <Grid container spacing={1} className="header-container" alignItems="center" justifyContent="center">
                <Grid item xs={2} className="logo">
                    <img onClick={onLogoClicked} src={"/assets/DSAI-logo.png"} alt="company-logo" className="header-logo"/>
                </Grid>
                <Grid item xs={10}>
                    <Grid container justifyContent="flex-end" className="menu-items-container" alignItems="center">
                        <Grid item xs="auto" className="menu-item">
                            <Typography variant="h6" onClick={onSurgeonClicked} className="menu-item-text">
                                PROFESSIONALS
                            </Typography>
                        </Grid>
                        <Grid item xs="auto" className="menu-item" >
                            <Typography variant="h6" onClick={onPatientClicked} className="menu-item-text">
                                PATIENTS
                            </Typography>
                        </Grid>
                        <Grid item xs="auto" className="menu-item" onClick={onAboutUsClicked}>
                            <Typography variant="h6" className="menu-item-text">
                                ABOUT US
                            </Typography>
                        </Grid>
                        <Grid item xs="auto" className="menu-item">
                            <Typography variant="h6" onClick={onBlogClicked} className="menu-item-text">
                                BLOG
                            </Typography>
                        </Grid>
                        <Grid item xs="auto" className="menu-item" onClick={onContactUsClicked}>
                            <Typography variant="h6" className="menu-item-text">
                                REQUEST DEMO
                            </Typography>
                        </Grid>
                        <Grid item xs="auto" className="menu-item-circled" >
                            <Button variant="contained" onClick={onLogInClicked} color="primary"
                              style={{maxWidth: '180px', maxHeight: '60px', minWidth: '180px', minHeight: '60px'}}>
                                Login
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    const mobileMenu = () => {
        return (
            <Grid container justifyContent="flex-end">
                <Grid item xs={6} className="logo">
                        <img onClick={onLogoClicked} src={"/assets/DSAI-logo.png"} alt="company-logo" className="mobile-header-logo"/>
                </Grid>
                <Grid item xs={4}/>
                <Grid item xs={2}>
                    <IconButton
                        aria-label="open drawer"
                        onClick={() => setDrawerOpen(!drawerOpen)}
                        >
                            {drawerOpen 
                            ? 
                                <KeyboardDoubleArrowDown fontSize="large" className="hamgurger-icon" />
                            :
                                <MenuIcon fontSize="large" className="hamgurger-icon"/>
                            }   
                    </IconButton>

                </Grid>
            </Grid>
        )
    }

    const dropDownContents = () => {
        return (
            <Grid container 
                spacing={2}
                direction="column"
                className="drop-down-menu-content">
                <Grid item xs={1} />
                <Grid item xs="auto" onClick={onSurgeonClicked} className="drow-down-menu-item">
                    <Typography variant="h6" className="drop-down-menu-item-text">
                        PROFESSIONALS
                    </Typography>
                </Grid>
                <Grid item xs="auto" onClick={onPatientClicked} className="drow-down-menu-item">
                    <Typography variant="h6"className="drop-down-menu-item-text">
                        PATIENTS
                    </Typography>
                </Grid>
                <Grid item xs="auto" onClick={onAboutUsClicked} className="drow-down-menu-item">
                    <Typography variant="h6" className="drop-down-menu-item-text">
                        ABOUT US
                    </Typography>
                </Grid>
                <Grid item xs="auto" onClick={onBlogClicked} className="drow-down-menu-item">
                    <Typography variant="h6" className="drop-down-menu-item-text">
                        BLOG
                    </Typography>
                </Grid>
                <Grid item xs="auto" onClick={onContactUsClicked} className="drow-down-menu-item">
                    <Typography  variant="h6" className="drop-down-menu-item-text">
                        REQUEST DEMO
                    </Typography>
                </Grid>
                <Grid item xs="auto" onClick={onLogInClicked} className="drow-down-menu-item">
                    <Typography  variant="h6" className="drop-down-menu-item-text">
                        LOGIN
                    </Typography>
                </Grid>
                <Grid item xs={1} />
            </Grid>
        )
    }

    return (
        <div>
            <AppBar position="sticky" className="appBar" elevation={0}>
                {(windowSize.width > 1090) 
                        ?
                        <div>
                            {desktopMenu()}
                        </div>
                        :
                        <div>
                            {mobileMenu()}
                        </div>
                    }
            </AppBar>
            <Drawer
                anchor="top"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                className="drop-down-menu"
                >
                {dropDownContents()}
            </Drawer>
        </div>
    )
}

export default Header;